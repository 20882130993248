import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views-react-18-fix";

import { steps } from "./steps";
import { Paper } from "@mui/material";

function SwipeableTextMobileStepper({ props }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  //const [step, setStep] = React.useState(steps[activeStep]);
  //const maxSteps = steps.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () =>
    steps?.[activeStep]?.nextFn?.({
      ...props,
      handleStepChange,
    });
  const handleSecondary = () =>
    steps?.[activeStep]?.secondaryFn?.({
      ...props,
      handleStepChange,
    });
  const handleBack = () =>
    steps?.[activeStep]?.backFn?.({
      ...props,
      handleStepChange,
    });

  return (
    <Paper elevation={2}>
      {/*      <Box padding={2}>
        <Button>Save and go back</Button>
      </Box> */}
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents={false}
      >
        {steps.map((s, index) => (
          <div key={s.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                sx={{
                  height: 450,
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                }}
                padding={3}
              >
                <Box width="100%" textAlign={"center"}>
                  <Typography variant="h6">
                    {index + 1}. {s.label}
                  </Typography>
                  <Box padding={s?.disablePadding ? 0 : 2} />
                  {s.Component(props)}
                </Box>
              </Box>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        sx={{
          bgcolor: "primary.light",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
        variant={null}
        //steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Box>
            {steps[activeStep]?.secondaryFn ? (
              <Button
                size="large"
                onClick={handleSecondary}
                disabled={steps[activeStep]?.disabled?.(props) ?? false}
                sx={{
                  color: "#fff",
                }}
              >
                {steps[activeStep].secondaryButtonText}
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            ) : null}
            <Button
              size="large"
              onClick={handleNext}
              disabled={steps[activeStep]?.disabled?.(props) ?? false}
              sx={{
                color: "#fff",
              }}
            >
              {steps[activeStep].nextButtonText ?? "Next"}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </Box>
        }
        backButton={
          steps[activeStep]?.backFn ? (
            <Button
              disabled={false}
              size="large"
              sx={{
                color: "#fff",
                //visibility: steps[activeStep]?.hideBack ? "hidden" : "visible",
              }}
              onClick={handleBack}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {steps[activeStep]?.backButtonText ?? "Back"}
            </Button>
          ) : (
            <div />
          )
        }
      />
    </Paper>
  );
}

export default SwipeableTextMobileStepper;
