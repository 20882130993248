import React, { useReducer, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Box } from "@mui/system";

import { useChallenge, useCreateChallenge } from "../services/challenges";
import { getChallenge } from "../models/challenge";
import { challengeReducer } from "./challengeReducer";
import SwipeableTextMobileStepper from "./SwipeableTextMobileStepper";

const initChallenge = getChallenge({ id: -1 });

export default function Create() {
  const navigate = useNavigate();
  const location = useLocation();

  // local state
  const [formId, setFormId] = useState(location.state?.formId ?? -1);
  const [challenge, challengeDispatch] = useReducer(
    challengeReducer,
    initChallenge
  );
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const back = () => navigate(-1);
  const end = () => navigate(`/f/${challenge.linkCode}`);

  // load a challenge from server
  const { isLoading } = useChallenge({
    formId,
    enabled: formId !== -1 && loading,
    onSuccess: (data) => {
      if (loading) {
        const challengeObj = data?.challenges?.[0];
        challengeDispatch({
          type: "INIT_CHALLENGE",
          payload: challengeObj,
        });
      }
      setLoading(false);
    },
  });

  // save a challenge
  const saveMutation = useCreateChallenge({
    onSuccess: (data) => {
      if (data?.status === 1) {
        setLoading(false);
        if (data?.formId > 0) {
          // update the formId
          setFormId(data.formId);
        }
        if (data?.challengeId > 0) {
          // update the challengeId
          challengeDispatch({
            type: "UPDATE_CHALLENGE",
            payload: { id: data.challengeId },
          });
        }
        if (data?.linkCode) {
          // update linkCode
          challengeDispatch({
            type: "UPDATE_CHALLENGE",
            payload: { linkCode: data.linkCode },
          });
          // copy to clipboard
          const url = `${process.env.REACT_APP_ROOT_PATH}${process.env.REACT_APP_BASE_PATH}/f/${data.linkCode}`;
          navigator.clipboard.writeText(url);
        }
      }
    },
  });

  // submit a challenge
  const handleSubmit = ({ publish = false }) => {
    if (challenge.progressStatus) return; // challenge is already published, no need to submit
    let challengeObj = {
      id: challenge.id,
      question: challenge.question,
      detailsLink: challenge.detailsLink,
      myLastValue: challenge.myLastValue,
      deadline: challenge.deadline.unix(),
      eventStart: challenge.eventStart.unix(),
      eventStop: challenge.eventStop.unix(),
      minValue: challenge.minValue,
      maxValue: challenge.maxValue,
      minAnchor: challenge.minAnchor,
      maxAnchor: challenge.maxAnchor,
      challengeType: challenge.challengeType,
      settings: challenge.settings,
      publishStart: challenge.publishStart?.unix(),
    };
    let data = {
      formId,
      challengesJSON: JSON.stringify([challengeObj]),
    };
    if (publish) {
      data.publish = 1; // lock the challenge
    }
    saveMutation.mutate(data);
  };
  return (
    <Box>
      <Container maxWidth="md">
        <SwipeableTextMobileStepper
          props={{
            isLoading,
            challenge,
            challengeDispatch,
            handleSubmit,
            end,
            back,
          }}
        />
      </Container>
    </Box>
  );
}
