import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

import { useQueryClient } from "react-query";
import { useFollow } from "../services/followers";
import MobileDesktopButton from "../Common/MobileDesktopButton";

export default function Profile({
  id,
  handle,
  following,
  postSearch,
  variant,
}) {
  const queryClient = useQueryClient();
  const followMutation = useFollow({
    onSuccess: () => {
      // refetch list
      postSearch();
      // refetch followers
      // refetch feed
      queryClient.invalidateQueries({ queryKey: ["followers"] });
      queryClient.invalidateQueries({ queryKey: ["following"] });
      queryClient.invalidateQueries({ queryKey: ["feed"] });
    },
  });
  const FollowButton = (
    <MobileDesktopButton
      startIcon={<PersonAddAlt1Icon />}
      onClick={() => followMutation.mutate({ handle })}
      size="small"
    >
      Subscribe
    </MobileDesktopButton>
  );
  const UnfollowButton = (
    <MobileDesktopButton
      startIcon={<PersonOffIcon />}
      onClick={() => followMutation.mutate({ handle, unfollow: 1 })}
      size="small"
    >
      Unsubscribe
    </MobileDesktopButton>
  );
  const LeaderBoardButton = (
    <MobileDesktopButton
      startIcon={<SportsScoreIcon />}
      component={Link}
      to={`/b/${id}`}
      size="small"
    >
      View leaderboard
    </MobileDesktopButton>
  );
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <Box
          sx={
            variant === "list"
              ? { display: "flex", justifyContent: "space-between" }
              : null
          }
        >
          <CardContent>
            <Typography variant="h5" component="div">
              {handle}
            </Typography>
          </CardContent>
          {variant === "row" && following === 1 ? (
            <CardActions>{LeaderBoardButton}</CardActions>
          ) : (
            <CardActions>
              {following === 1 ? UnfollowButton : FollowButton}
            </CardActions>
          )}
        </Box>
      </Card>
    </Box>
  );
}
