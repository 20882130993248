import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Copyright from "./Copyright";
import { useChangePass } from "../services/login";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

const enterNewPassSchema = z
  .object({
    password: z
      .string()
      .nonempty("Password is required")
      .min(8, "Password must be more than 8 characters")
      .max(32, "Password must be less than 32 characters"),
    passwordConfirm: z.string().nonempty("Please confirm your password"),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    path: ["passwordConfirm"],
    message: "Passwords do not match",
  });

export default function ChangePass() {
  const {
    register,
    setError,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(enterNewPassSchema),
  });
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("i")) ?? -1;
  const passwordResetCode = searchParams.get("c") ?? "";

  const { mutate } = useChangePass({
    onSuccess: (data) => {
      const status = data?.status ?? 0;
      const email = data?.email ?? "";
      if (status > 0) {
        navigate(`/login?email=${email}`);
      } else {
        setError("password", { type: "server", message: "" });
        setError("passwordConfirm", {
          type: "server",
          message: "Could not change password",
        });
      }
    },
  });

  // submit the registration
  const onSubmitHandler = (values) =>
    mutate({ password: values.password, passwordResetCode, id: userId });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box marginTop={8} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change password
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} display="none">
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                fullWidth
                name="password"
                label="Password"
                type={showPass ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                error={!!errors["password"]}
                helperText={
                  errors["password"] ? errors["password"].message : ""
                }
                {...register("password")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="passwordConfirm"
                label="Confirm password"
                type={showPass ? "text" : "password"}
                id="passwordConfirm"
                autoComplete="new-password"
                error={!!errors["passwordConfirm"]}
                helperText={
                  errors["passwordConfirm"]
                    ? errors["passwordConfirm"].message
                    : ""
                }
                {...register("passwordConfirm")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={showPass}
                      onChange={(event) => setShowPass(event.target.checked)}
                      color="primary"
                    />
                  }
                  label={<Typography>Show password</Typography>}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save new password
          </Button>
        </Box>
      </Box>

      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
