import { IconButton, Tooltip } from "@mui/material";

import Comment from "@mui/icons-material/Comment";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";

export default function ShowLinkButton({ showLink, setShowLink }) {
  function renderIcon(enabled) {
    if (enabled){
      return(<AddCommentOutlinedIcon />);
    }else{
      return(null);
    }
  }
  
  return (
    <Tooltip title={"Add link"}>
      <IconButton
        onClick={() => {
            setShowLink(!showLink);
          }
        }
      >
      {renderIcon(!showLink)}
      </IconButton>
    </Tooltip>
  );
}
