import { IconButton, Tooltip } from "@mui/material";

import Comment from "@mui/icons-material/Message";
import CommentsDisabled from "@mui/icons-material/ChatBubbleOutline";

export default function ShowAllContentButton({ showAllContent, setShowAllContent }) {
  function renderIcon(enabled) {
    if (enabled){
      return(<Comment />);
    }else{
      return(<CommentsDisabled />);
    }
  }
    
  var title="Show comments";  
  if (showAllContent){
    title="Hide comments";
  }

  return (
    <Tooltip title={title}>
      <IconButton
        onClick={() => {
            setShowAllContent(!showAllContent);
          }
        }
      >
      {renderIcon(showAllContent)}
      </IconButton>
    </Tooltip>
  );
}
