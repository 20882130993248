import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import AuthContext from "../authContext";
import Logo from "../Toolbar/Logo";

export default function Toolbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, loginDispatch } = useContext(AuthContext);
  const handleRedirect = () => {
    loginDispatch({
      type: "SET_ON_SUCCESS",
      payload: () => {
        navigate(location.pathname);
      },
    }); // redirect back to current location if login will be successful
  };
  return (
    <Box>
      <Box bgcolor={"#fff"}>
        <Box display="flex" justifyContent={"space-between"}>
          <Logo
            onClick={
              login.token
                ? () => navigate("/feed")
                : () => {
                    handleRedirect();
                    navigate("/login");
                  }
            }
          />
          {login.token ? (
            <Typography variant="body1">
              Welcome <Link to="/profile">{login.handle}</Link>!
            </Typography>
          ) : (
            <Box display="flex" gap={2}>
              <Button component={Link} to="/register" onClick={handleRedirect}>
                Register
              </Button>
              <Button
                component={Link}
                to="/login"
                variant="contained"
                onClick={handleRedirect}
              >
                Login
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
