import { Button as MuiButton } from "@mui/material";
import { Link } from "react-router-dom";

export default function Button({ isActive, title, link, ...props }) {
  return (
    <MuiButton
      component={Link}
      to={link}
      variant={isActive ? "outlined" : "text"}
      {...props}
      sx={{ padding: 1 }}
    >
      {title}
    </MuiButton>
  );
}
