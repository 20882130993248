import { Button, IconButton, Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MobileDesktopButton(props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { children, ...other } = props;
  return isMobile ? (
    <Tooltip title={children}>
      <IconButton {...other}>{props.startIcon}</IconButton>
    </Tooltip>
  ) : (
    <Button {...other}>{children}</Button>
  );
}
