import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Avatar,
  ListItemAvatar,
  Typography,
  Chip,
  CircularProgress,
  ListItemText,
} from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import GavelIcon from "@mui/icons-material/Gavel";
import { getPublicChallenge, getPublicForm } from "../models/challenge";
import AuthContext from "../authContext";

export default function DataTable({ rows }) {
  const [checked, setChecked] = useState([]);
  const {
    login: { handle },
  } = useContext(AuthContext);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getChallengeLabel = ({
    isPublished,
    isExpired,
    canAdjudicate,
    isAdjudicated,
    deleted,
    ownerHandle,
  }) => {
    if (deleted) {
      return {
        label: "Deleted",
        icon: null,
        iconColor: "inherit",
        variant: "outlined",
      };
    }
    if (isAdjudicated) {
      return {
        label: "Decided",
        icon: <GavelIcon sx={{ rotate: "-45deg" }} fontSize="small" />,
        iconColor: "inherit",
        variant: "outlined",
      };
    }
    if (canAdjudicate && handle === ownerHandle)
      return {
        label: "Make decision",
        icon: <GavelIcon fontSize="small" />,
        iconColor: "inherit",
        variant: "filled",
      };
    if (canAdjudicate && handle !== ownerHandle)
      return {
        label: "Waiting for decision",
        icon: <GavelIcon fontSize="small" />,
        iconColor: "inherit",
        variant: "outlined",
      };
    if (isExpired && isPublished)
      return {
        label: "Expired",
        icon: <CircleIcon fontSize="small" />,
        iconColor: "#EE4B2B",
        variant: "outlined",
      };
    if (isPublished)
      return {
        label: "Active",
        icon: <CircleIcon fontSize="small" />,
        iconColor: "#44b700",
        variant: "outlined",
      };
    return {
      label: "Draft",
      icon: null,
      iconColor: "inherit",
      variant: "filled",
    };
  };

  return (
    <Box>
      {rows != null ? (
        <List>
          {[...rows].reverse().map((f) => {
            const form = getPublicForm(f);
            const id = form.id;
            const linkCode = form.linkCode;

            const challenge = getPublicChallenge(form.challenges?.[0]);
            const challengeLabel = getChallengeLabel({
              ...challenge,
              ownerHandle: form.ownerHandle,
              deleted: form.deleted,
            });

            /*  const isExpired = form.challenges?.[0].isExpired ?? false;
            const question1 = form.challenges?.[0].question ?? "";
            const canAdjudicate = form.challenges?.[0].canAdjudicate ?? false;
            const challengeLabel = getChallengeLabel({
              isPublished,
              isExpired,
              canAdjudicate,
            }); */

            return (
              <ListItem key={id} disablePadding selected={checked.includes(id)}>
                <ListItemButton
                  role={undefined}
                  //onClick={handleToggle(id)}
                  component={Link}
                  to={challenge.isPublished ? `/f/${linkCode}` : "/create"}
                  state={challenge.isPublished ? {} : { formId: id }}
                  sx={{
                    borderRadius: 1,
                    display: "flex",
                    //justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ width: 32, height: 32 }} variant="rounded">
                      {challenge.question.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <Typography variant="inherit" noWrap>
                    {`${challenge.question} ${
                      form.ownerHandle === handle
                        ? ""
                        : `• by ${form.ownerHandle}`
                    } `}{" "}
                  </Typography>
                </ListItemButton>
                <Chip
                  variant={challengeLabel.variant}
                  icon={challengeLabel.icon}
                  label={challengeLabel.label}
                  sx={{
                    "& .MuiChip-icon": { color: challengeLabel.iconColor },
                    justifyContent: "end",
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
