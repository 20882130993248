import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

export const buttons = [
  {
    id: "feed",
    title: "Feed",
    link: "/feed",
    paths: ["/feed"],
    icon: <FeedOutlinedIcon />,
  },
  {
    id: "responses",
    title: "Responses",
    link: "/responses",
    paths: ["/responses"],
    icon: <AssignmentOutlinedIcon />,
  },
  /*
  {
    id: "profile",
    title: "Channels",
    link: "/profile",
    paths: ["/profile"],
    icon: <SupervisorAccountIcon />,
  },
  */
  {
    id: "leaderboard",
    title: "Teams",
    link: "/b/0",
    paths: ["/b"],
    icon: <SportsScoreIcon />,
  },
];
