import { useFavorite } from "../services/followers";
import { IconButton, Tooltip } from "@mui/material";

import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

export default function FavoriteButton({ channelId, onSuccess, handle, pred }) {
  const favoriteMutation = useFavorite({ onSuccess: onSuccess });

  const level=pred.favorite==false;

  function renderFavoriteIcon() {
    if (pred.favorite){
      return(<StarIcon />);
    }else{
      return(<StarBorderIcon />);
    }
  }

  var title='Mark as favorite';
  if (pred.favorite){
    title='Unmark as favorite ';
  }
  
  return (
    <Tooltip title={title}>
      <IconButton
        onClick={() =>
          favoriteMutation.mutate({
            handle,
            channelId,
            level,
            pred
          })
        }
      >
      {renderFavoriteIcon()}
      </IconButton>
    </Tooltip>
  );
}
