import { useFollow } from "../services/followers";
import { IconButton, Tooltip } from "@mui/material";

import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";

export default function FollowButton({ follow = true, onSuccess, handle }) {
  const followMutation = useFollow({ onSuccess: onSuccess });

  return (
    <Tooltip title={follow ? "Subscribe" : "Unsubscribe"}>
      <IconButton
        onClick={() =>
          followMutation.mutate({
            handle: handle,
            unfollow: follow ? 0 : 1,
          })
        }
      >
        {follow ? <PersonAddAlt1OutlinedIcon /> : <PersonOffOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
}
