import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import SendIcon from "@mui/icons-material/Send";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Copyright from "./Copyright";
import { useLogin } from "../services/login";
import AuthContext from "../authContext";
import { Alert, Divider } from "@mui/material";

const loginSchema = z.object({
  email: z.string().nonempty("Email is required"),
  password: z.string().nonempty("Password is required"),
  remember: z.boolean().optional(),
});

export default function Login({ handleMode = () => {} }) {
  const {
    register,
    setError,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(loginSchema),
  });
  const navigate = useNavigate();
  const { onLogin, login } = useContext(AuthContext);
  const isSubmit = login.variant === "submit";
  const [searchParams, setSearchParams] = useSearchParams();
  const showAlert = searchParams.has("v");
  const isVerified = searchParams.get("v") === "1";
  const initEmail = searchParams.get("email") ?? "";

  const { mutate } = useLogin({
    onSuccess: (data, vars) => {
      const status = data?.status ?? 0;
      switch (status) {
        case 0: // login failed
        case 4: // wrong email or password
          setError("email", { type: "server", message: "" });
          setError("password", {
            type: "server",
            message: "Wrong email or password",
          });
          break;
        case 1: // succesful login
        case 2: // login succesful but legal needs updating
          const remember = vars?.remember ?? false;
          const userId = data?.userId ?? 0;
          if (remember) {
            localStorage.setItem(
              "remember",
              JSON.stringify({ userId: userId, remember: true })
            );
          } else {
            localStorage.setItem(
              "remember",
              JSON.stringify({ userId: userId, remember: false })
            );
          }
          onLogin();
          break;
        case 3:
          // login failed, email not verified (check your inbox for a verification email or recreate account
          setError("email", {
            type: "server",
            message:
              "Email not verified (check your inbox/spam folder for a verification email or recreate account)",
          });
          break;
        case 5:
          // two factor
          break;
        default:
          break;
      }
    },
  });
  const onSubmitHandler = (values) => mutate(values);

  return (
    <Grid container component="main" sx={!isSubmit ? { height: "100vh" } : {}}>
      <CssBaseline />
      {!isSubmit ? (
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/photo-1627806951929-b12533350dc8.jfif)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      ) : null}
      <Grid
        item
        xs={12}
        sm={!isSubmit ? 8 : 12}
        md={!isSubmit ? 5 : 12}
        component={Paper}
        elevation={!isSubmit ? 6 : 0}
        square
      >
        <Box width={"100%"}>
          {showAlert && isVerified ? (
            <Alert severity="success">
              Your account is succesfully verified
            </Alert>
          ) : null}
          {showAlert && !isVerified ? (
            <Alert severity="error">
              Your account is not registered or already verified
            </Alert>
          ) : null}
        </Box>
        <Box
          sx={[
            {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            },
            !isSubmit ? { my: 8, mx: 4 } : { mb: 2 },
          ]}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login to Cassandras
          </Typography>
          {!isSubmit ? null : (
            <Typography variant="body2">
              to submit your answer and view your friend's responses.
            </Typography>
          )}

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmitHandler)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              defaultValue={initEmail}
              autoComplete="email"
              error={!!errors["email"]}
              helperText={errors["email"] ? errors["email"].message : ""}
              {...register("email")}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!errors["password"]}
              helperText={errors["password"] ? errors["password"].message : ""}
              {...register("password")}
            />
            <Box textAlign={"left"}>
              <FormControlLabel
                control={<Checkbox {...register("remember")} color="primary" />}
                id="remember"
                name="remember"
                label="Remember me"
                defaultValue={false}
              />
            </Box>
            <Box padding={1} />
            <Button
              startIcon={!isSubmit ? null : <SendIcon />}
              type="submit"
              fullWidth
              variant="contained"
            >
              {!isSubmit ? "Login" : "Login and submit"}
            </Button>
            <Box padding={1} />
            <Link to="/forgetpass">
              <Typography variant="body2">Forgotten password?</Typography>
            </Link>
            <Box padding={1} />

            <Divider />

            <Box padding={1} />

            <Button
              onClick={
                !isSubmit
                  ? () => navigate("/register")
                  : () => handleMode("register")
              }
              sx={[
                {
                  "&:hover": {
                    backgroundColor: "#00695f",
                  },
                },
                { backgroundColor: "#009688" },
              ]}
              variant="contained"
            >
              Create new account
            </Button>
            {!isSubmit ? <Copyright sx={{ mt: 2 }} /> : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
