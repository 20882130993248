import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MobileDesktopButton from "../Common/MobileDesktopButton";
import { useDeleteFrom } from "../services/challenges";

export default function DeleteDialog({ formId, refetch }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteMutation = useDeleteFrom({
    onSuccess: (data) => {
      if (data?.status === 1) {
        if (data?.status === 1) {
          // close the dialog
          handleClose();
          refetch(); // refetch the form
        }
      }
    },
  });

  return (
    <div>
      <MobileDesktopButton
        variant="outlined"
        startIcon={<DeleteOutlineOutlinedIcon />}
        color="secondary"
        onClick={handleClickOpen}
      >
        Delete
      </MobileDesktopButton>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Delete challenge?</DialogTitle>
        <DialogContent>
          Do you want to permanently delete this challenge?
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => deleteMutation.mutate({ formId })}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
