import * as React from "react";
import Stack from "@mui/material/Stack";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import CookiePolicyDialog from "./CookiePolicy";

export default function CookiesBanner() {
  const [bannerOpen, setBannerOpen] = React.useState(false);

  const closeBanner = () => {
    setBannerOpen(false);
    localStorage.setItem("allowCookies", JSON.stringify(true));
  };

  useEffect(() => {
    // show cookiebanner if user has not give consent
    const allowCookies = JSON.parse(localStorage.getItem("allowCookies"));
    if (!allowCookies) {
      setBannerOpen(true);
    } else {
      setBannerOpen(false);
    }
  }, []);

  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={bannerOpen}>
        <Paper style={{zIndex:1000}}
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          variant="outlined"
          tabIndex={-1}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            //justifyContent="space-between"
            justifyContent={"flex-end"}
            gap={4}
          >
            <Box
              sx={{
                flexShrink: 1,
                alignSelf: { xs: "flex-start", sm: "center" },
              }}
            >
              <Typography fontWeight="bold">
                This website uses cookies
              </Typography>
              <Typography variant="body2">
                cassandras.be relies on functional cookies only.{" "}
              </Typography>
            </Box>
            <Stack
              gap={2}
              direction={{
                xs: "row-reverse",
                sm: "row",
              }}
              sx={{
                flexShrink: 0,
                alignSelf: { xs: "flex-end", sm: "center" },
              }}
            >
              <CookiePolicyDialog />

              <Button size="small" onClick={closeBanner} variant="contained">
                Allow all
              </Button>
              {/*  <Button size="small" onClick={closeBanner}>
                Reject all
              </Button> */}
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
}
