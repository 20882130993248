import Login from "./Login.js";
import Register from "./Login.js/Register";
import ForgetPass from "./Login.js/ForgetPass";
import Page from "./Page.js";
import Challenges from "./Challenges";
import Create from "./Create/index.js";
import Verify from "./Login.js/Verify.js";
import Form from "./Form/index.js";
import Thanks from "./Login.js/Thanks.js";
import Responses from "./Responses/index.js";
import ChangePass from "./Login.js/ChangePass.js";
import Profile from "./Profile/index.js";
import Leaderboard from "./Leaderboard/index.js";
import Feed from "./Feed/index.js";

export const routes = [
  {
    path: "/",
    element: <Login />,
    publicPage: true,
  },
  {
    path: "/login",
    element: <Login />,
    publicPage: true,
  },
  {
    path: "/register",
    element: <Register />,
    publicPage: true,
  },
  {
    path: "/verify",
    element: <Verify />,
    publicPage: true,
  },
  {
    path: "/forgetpass",
    element: <ForgetPass />,
    publicPage: true,
  },
  {
    path: "/changePass",
    element: <ChangePass />,
    publicPage: true,
  },
  {
    path: "/home",
    element: <Page Body={<div>Home</div>} />,
    publicPage: false,
  },
  {
    path: "/challenges",
    element: <Page Body={<Responses />} />,
    publicPage: false,
  },
  {
    path: "/responses",
    element: <Page Body={<Responses />} />,
    publicPage: false,
  },
  {
    path: "/create",
    element: <Page Body={<Create />} />,
    publicPage: false,
  },
  {
    path: "/settings",
    element: <Page Body={<div>Settings</div>} />,
    publicPage: false,
  },
  {
    path: "/f/:linkCode",
    element: <Form />,
    publicPage: true,
  },
  {
    path: "/thanks",
    element: <Thanks />,
    publicPage: true,
  },
  {
    path: "/profile",
    element: <Page Body={<Profile />} />,
    publicPage: false,
  },
  {
    path: "/b/:challengerId",
    element: <Page Body={<Leaderboard />} />,
    publicPage: false,
  },
  {
    path: "/feed",
    element: <Page Body={<Feed />} />,
    publicPage: false,
  },
];
