import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "./authContext";
import { useSettings } from "./services/login";

// Before rendering component, check if user is logged in. If user is not logged in, redirect to the login page
export default function ProtectedRoute({ children, publicPage }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginDispatch } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
 
  const settingsMutation = useSettings({
    onSuccess: (data) => {
      console.log(location.pathname);
      // page is not public and user is not logged in or logged in with wrong email address
      const currentEmail=data.email??"";
      const initEmail = searchParams.get("email") ?? currentEmail;
      console.log(initEmail);
      if ((!publicPage && data?.status === -1) || (currentEmail!==initEmail)) {
        loginDispatch({
          type: "SET_ON_SUCCESS",
          payload: () => navigate(location.pathname),
        }); // redirect back to current location if login will be successful
        navigate("/login?email="+encodeURIComponent(initEmail)); // redirect to login page
      }

      // user is logged in
      if (data?.status === 1) {
        loginDispatch({ type: "SET_SETTINGS", payload: data }); // save the usersettings
      }
    },
  });

  useEffect(() => {
    settingsMutation.mutate();
  }, []);

  return children;
}
