import moment from "moment";
import { DEFAULT_TYPE } from "../Form/types";

class Settings {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.valueFormat = obj.valueFormat ?? "";
  }
}

const getSettings = (obj) => new Settings(obj);

export const tomorrow = {
  eventStart: moment().add(1, "day").hours(0).minutes(0).seconds(0),
  eventStop: moment().add(2, "days").hours(0).minutes(0).seconds(0),
  deadline: moment().add(1, "day").hours(0).minutes(0).seconds(0),
};
export const nextWeek = {
  eventStart: moment()
    .add(1, "week")
    .isoWeekday(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0),
  eventStop: moment()
    .add(2, "weeks")
    .isoWeekday(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0),
  deadline: moment()
    .add(1, "week")
    .isoWeekday(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0),
};
export const nextMonth = {
  eventStart: moment()
    .add(1, "month")
    .date(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0),
  eventStop: moment()
    .add(2, "months")
    .date(0)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0),
  deadline: moment()
    .add(1, "month")
    .date(1)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0),
};

class Challenge {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : Math.round(Math.random() * 10000000);
    this.question = obj.question != null ? obj.question : "";
    this.detailsLink = obj.detailsLink != null ? obj.detailsLink : "";
    this.deadline =
      obj.deadline != null ? moment.unix(obj.deadline) : tomorrow.deadline;
    this.eventStart =
      obj.eventStart != null
        ? moment.unix(obj.eventStart)
        : tomorrow.eventStart;
    this.eventStop =
      obj.eventStop != null ? moment.unix(obj.eventStop) : tomorrow.eventStop;
    this.tz = obj.tz != null ? obj.tz : null;
    this.progressStatus =
      obj.progressStatus != null ? Boolean(obj.progressStatus) : false;
    const minValueDefault = obj?.minValue ?? 0;
    const maxValueDefault = obj?.maxValue ?? 100;
    this.minValue = obj.minValue != null ? obj.minValue : minValueDefault;
    this.maxValue = obj.maxValue != null ? obj.maxValue : maxValueDefault;
    this.minAnchor = obj.minAnchor != null ? obj.minAnchor : "No";
    this.maxAnchor = obj.maxAnchor != null ? obj.maxAnchor : "Yes";
    this.settings = getSettings(obj.settings);

    this.challengeType =
      obj.challengeType != null ? obj.challengeType : DEFAULT_TYPE;

    const otherPredictions =
      obj.otherPredictions != null
        ? getOtherPredictions(obj.otherPredictions)
        : [];

    /*
    const myLastValueDefault = Math.round(
      otherPredictions.filter(
        (pred) => pred.type === "stat" && pred.handle === "mean"
      )?.[0]?.lastValue ??
        Math.floor(Math.abs(maxValueDefault - minValueDefault) / 2)
    );
    */

    const myLastValueDefault=obj.defaultValue ??
      Math.floor((minValueDefault + maxValueDefault) / 2)

    this.myLastValue =
      obj.myLastValue != null ? obj.myLastValue : myLastValueDefault;

    this.myLastComment =
      obj.myLastComment != null ? obj.myLastComment : "";

    this.myWeekRank =
      obj.myWeekRank != null ? obj.myWeekRank : null;

    this.myLocalWeekRank =
      obj.myLocalWeekRank != null ? obj.myLocalWeekRank : null;

    this.Npredictors = obj.Npredictors != null ? obj.Npredictors : 0;

    this.outcomeBinary = obj.outcomeBinary != null ? obj.outcomeBinary : null;
    this.outcomeValue = obj.outcomeValue != null ? obj.outcomeValue : null;

    this.ownerId = obj.ownerId != null ? obj.ownerId : 0;
    /*
    const date = new Date(obj?.published * 1000);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const published =
      obj.published != null && obj.published != 0
        ? new Date(date.getTime() - userTimezoneOffset)
        : false;
    this.published = published;
    */
    this.publishStart =
      obj.publishStart != null ? moment.unix(obj.publishStart) : null;
    this.isExpired = obj.isExpired != null ? Boolean(obj.isExpired) : false;
    this.isPublished = obj.isPublished != null ? Boolean(obj.isPublished) : false;
    //canAdjudicate means also published
    this.canAdjudicate =
      obj.canAdjudicate != null ? (Boolean(obj.canAdjudicate) && this.isPublished) : false;
    this.isAdjudicated =
      obj.isAdjudicated != null ? Boolean(obj.isAdjudicated) : false;
    this.deleted = obj.deleted != null ? Boolean(obj.deleted) : false;
    this.lastFollowingId =
      obj.lastFollowingId != null ? obj.lastFollowingId : null;
    this.done = obj.done != null ? Boolean(obj.done) : false;

    this.comment = obj.comment != null ? obj.comment : "";

    this.linkVisible=false;

    this.teamId = obj.teamId != null ? obj.teamId : -1;
    this.teamHandle = obj.teamHandle != null ? obj.teamHandle : '';
    this.teamN = obj.teamN != null ? obj.teamN : -1;
    this.teamNpredictors = obj.teamNpredictors != null ? obj.teamNpredictors : -1;
  }
}

export function getChallenge(obj) {
  return new Challenge(obj);
}

class OtherPrediction {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? Number(obj.id) : -1;
    this.lastValue = obj.lastValue != null ? obj.lastValue : 0;
    this.comment = obj.comment != null ? obj.comment : "";
    this.handle = obj.handle != null ? obj.handle : "";
    this.email = obj.email != null ? obj.email : "";
    this.weekScore = obj.weekScore != null ? Math.round(obj.weekScore) : null;
    this.type = obj.type != null ? obj.type : "";
    this.weekRank = obj.weekRank != null ? obj.weekRank : null;
    this.localWeekRank = obj.localWeekRank != null ? obj.localWeekRank : null;
    this.following = obj.following != null ? Boolean(obj.following) : true;
    this.favorite = obj.favorite != null ? Boolean(obj.favorite) : false;
  }
}

function getOtherPrediction(otherPrediction) {
  return new OtherPrediction(otherPrediction);
}

function getOtherPredictions(otherPredictions) {
  //console.log("Creating otherPredictions list");
  const keys = Object.keys(otherPredictions);
  const list = keys.map((key) =>
    getOtherPrediction({ ...otherPredictions[key], id: key })
  );
  const sortedList = list.sort((a, b) => (a.weekRank===null)-(b.weekRank===null) || +(a.weekRank>b.weekRank)||-(a.weekRank<b.weekRank));
  return sortedList;
}

class PublicChallenge extends Challenge {
  constructor(obj) {
    obj = obj != null ? obj : {};
    super(obj);
    const otherPredictions =
      obj.otherPredictions != null
        ? getOtherPredictions(obj.otherPredictions)
        : [];
    this.otherPredictions = otherPredictions;
  }
}

export function getPublicChallenge(obj) {
  return new PublicChallenge(obj);
}

function getPublicChallenges(challenges) {
  const keys = Object.keys(challenges);
  const list = keys.map((key) =>
    getPublicChallenge({ ...challenges[key], id: key })
  );
  return list;
}

class PublicForm {
  constructor(obj) {
    obj = obj != null ? obj : {};
    //why? XXX
    this.id = obj.id != null ? obj.id : Math.round(Math.random() * 10000000);
    this.linkCode = obj.linkCode != null ? obj.linkCode : "";
    this.owner = obj.owner != null ? obj.owner : false;
    this.title = obj.title != null ? obj.title : "";
    this.description = obj.description != null ? obj.description : "";
    this.challenges =
      obj.challenges != null
        ? getPublicChallenges({ ...obj.challenges, deleted: obj.deleted })
        : [];
    this.ownerHandle = obj.ownerHandle != null ? obj.ownerHandle : "Unknown";
    this.deleted = obj.deleted != null ? Boolean(obj.deleted) : false;
  }
}

export function getPublicForm(publicForm) {
  return new PublicForm(publicForm);
}

export const getPublicForms = (forms) => {
  //console.log("Creating form list");
  const keys = Object.keys(forms);
  const list = keys.map((key) => getPublicForm({ ...forms[key], id: key }));
  //console.log(list);
  return list;
};
