import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./authContext";
import { useSettings } from "./services/login";
import { useQueryClient } from "react-query";

export default function AuthProvider({ children }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const loginReducer = (state, action) => {
    switch (action.type) {
      case "SET_VARIANT":
        return { ...state, variant: action.payload };
      case "SET_ON_SUCCESS":
        return { ...state, onSuccess: action.payload };
      case "CANCEL_LOGIN":
        return {
          ...state,
          variant: loginDefaults.variant,
          onSuccess: loginDefaults.onSuccess,
        };
      case "SET_STATE":
        return { ...state, state: { ...state.state, ...action.payload } };
      case "SET_SETTINGS":
        const status = action.payload?.status ?? -1;
        const handle = action.payload?.handle ?? "";
        const userId = action.payload?.userId ?? 0;
        const creatorRights = action.payload?.creatorRights ?? 0;
        const teamId = action.payload?.teamId ?? 0;
        const isVerified =
          action.payload?.emailVerified != null
            ? Boolean(action.payload.emailVerified)
            : false;

        const rememberObj = JSON.parse(localStorage.getItem("remember"));
        const isRemembered =
          userId === rememberObj?.userId && rememberObj?.remember;
        const login_expirationInterval = isRemembered ? 525600 : 1800;

        return {
          ...state,
          token: status,
          handle,
          userId,
          isVerified,
          creatorRights,
          teamId,
          login_expirationInterval,
        };
      default:
        return state;
    }
  };
  const loginDefaults = {
    token: null,
    handle: "",
    userId: 0,
    isVerified: false,
    onSuccess: () => {
      navigate("/feed");
    },
    variant: "default",
    state: {},
    login_expirationInterval: 1800,
  };
  const [login, loginDispatch] = useReducer(loginReducer, loginDefaults);

  const settingsMutation = useSettings({
    onSuccess: (data) => {
      queryClient.clear();
      loginDispatch({ type: "SET_SETTINGS", payload: data });
      console.log(login);
      if (login?.onSuccess) {
        login.onSuccess();
      } else {
        loginDefaults.onSuccess();
      }
    },
  });
  const handleLogin = () => settingsMutation.mutate(); // set a function that will be executed after successful login

  const value = {
    login,
    loginDispatch,
    onLogin: handleLogin, // login was succesful, set all settings
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
