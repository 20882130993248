import React, { useContext } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import Button from "./Button";
import { buttons } from "./buttons";
import AuthContext from "../authContext";

export default function NavButtons() {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    login: { creatorRights },
  } = useContext(AuthContext);

  return (
    <React.Fragment>
      {isMobile ? (
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 0, backgroundColor: "background.paper" }}
        >
          <Toolbar>
            <BottomNavigation
              showLabels
              //value={pathname}
              sx={{ width: "100%" }}
            >
              {buttons.map((button) =>
                button.id === "leaderboard" && creatorRights === 0 ? null : (
                  <BottomNavigationAction
                    key={button.id}
                    component={Link}
                    to={button.link}
                    label={button.title}
                    icon={button.icon}
                  />
                )
              )}
            </BottomNavigation>
          </Toolbar>
        </AppBar>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 4 }}>
            {buttons.map((button) =>
              button.id === "leaderboard" && creatorRights === 0 ? null : (
                <Button
                  key={button.id}
                  title={button.title}
                  isActive={button.paths.includes(pathname)}
                  link={button.link}
                />
              )
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}
