import React from "react";
import { TextField, Box, Alert, Typography, Button, Link } from "@mui/material";

import DatePicker from "./DatePicker";
import moment from "moment";
import CustomSlider from "../Form/CustomSlider";
import CustomDateCalendar from "./CustomDateCalendar";
import { nextMonth, nextWeek, tomorrow } from "../models/challenge";

export const steps = [
  {
    id: "create",
    label: "What is the statement you want others to assess?",
    required: true,
    Component: ({ isLoading, challenge, challengeDispatch }) => {
      return (
        <Box>
          <TextField
            disabled={isLoading || challenge.progressStatus}
            autoComplete="off"
            required
            fullWidth
            id="question"
            label=""
            name="question"
            value={challenge.question}
            onChange={(event) =>
              challengeDispatch({
                type: "UPDATE_CHALLENGE",
                payload: { question: event.target.value },
              })
            }
            placeholder="Example: Will it rain in New York?"
          />
          <Box padding={2} />
          <Box display="flex" gap={1} alignItems={"center"}>
            <Typography>This will happen between</Typography>
            <DatePicker
              label="Start"
              disabled={isLoading || challenge.progressStatus}
              value={challenge.eventStart}
              handleChange={(date) =>
                challengeDispatch({
                  type: "UPDATE_CHALLENGE",
                  payload: { eventStart: date },
                })
              }
            />
            <Typography>and</Typography>
            <DatePicker
              label="End"
              disabled={isLoading || challenge.progressStatus}
              value={challenge.eventStop}
              handleChange={(date) =>
                challengeDispatch({
                  type: "UPDATE_CHALLENGE",
                  payload: { eventStop: date },
                })
              }
            />
          </Box>
          <Typography sx={{ color: "red" }}>
            {!moment(challenge.eventStop).isSameOrAfter(challenge.eventStart)
              ? "End of challenge cannot be before challenge start"
              : null}
          </Typography>

          <Box textAlign={"left"}>
            <Button
              onClick={() => {
                challengeDispatch({
                  type: "UPDATE_CHALLENGE",
                  payload: tomorrow,
                });
              }}
            >
              Tomorrow
            </Button>
            <Button
              onClick={() => {
                challengeDispatch({
                  type: "UPDATE_CHALLENGE",
                  payload: nextWeek,
                });
              }}
            >
              Next week
            </Button>
            <Button
              onClick={() => {
                challengeDispatch({
                  type: "UPDATE_CHALLENGE",
                  payload: nextMonth,
                });
              }}
            >
              Next month
            </Button>
          </Box>

          <Box padding={2} />

          {(challenge.linkVisible || challenge.detailsLink!=="") ? (
            <Box display="flex" gap={1} alignItems={"center"}>
              <Typography>Details:</Typography>
              <TextField
                disabled={isLoading || challenge.progressStatus}
                autoComplete="off"
                fullWidth
                id="detailsLink"
                label=""
                name="detailsLink"
                value={challenge.detailsLink}
                onChange={(event) =>
                  challengeDispatch({
                    type: "UPDATE_CHALLENGE",
                    payload: { detailsLink: event.target.value },
                  })
                }
                placeholder="Example: https://osf.io/xturh/"
              />
            </Box>
          ):(     
            <Box textAlign={"left"}>
              <Typography
                component={Link}
                onClick={() => 
                  challengeDispatch({
                    type: "UPDATE_CHALLENGE",
                    payload: { linkVisible: true}
                  })
                }>
                Add link with details
              </Typography>
            </Box>
          )}
        </Box>
      );
    },
    // disabled next button
    disabled: ({ challenge }) => 
    !(challenge.question.length > 0) ||
    !(moment(challenge.eventStop).isSameOrAfter(challenge.eventStart)), 
    backButtonText: "Back to responses",
    backFn: ({ back }) => back(),
    nextFn: ({ handleStepChange,challenge,challengeDispatch }) =>{
      challengeDispatch({
        type: "UPDATE_CHALLENGE",
        payload: { deadline: challenge.eventStart },
      });
      handleStepChange(getStepIndexById("response"));
    },
  },
  {
    id: "response",
    label: "What is your personal prediction?",
    required: true,
    Component: ({ isLoading, challenge, challengeDispatch }) => (
      <Box paddingTop={5}>
        <CustomSlider
          tooltip={false}
          disabled={isLoading || challenge.progressStatus}
          handleValueChange={(slider) => {
            challengeDispatch({
              type: "UPDATE_CHALLENGE",
              payload: { myLastValue: slider },
            });
          }}
          challengeDispatch={challengeDispatch}
          challenge={challenge}
          mode="edit"
        />
      </Box>
    ),
    disabled: ({ challenge }) => !(typeof challenge.myLastValue === "number"),
    backFn: ({ handleStepChange }) =>
      handleStepChange(getStepIndexById("create")),
    nextFn: ({ handleStepChange, handleSubmit }) => {
      handleStepChange(getStepIndexById("deadline"));
      handleSubmit({ publish: false });
    },
  },
  {
    id: "deadline",
    label: "What is the deadline for the prediction?",
    disablePadding: true,
    required: true,
    Component: ({ isLoading, challenge, challengeDispatch }) => (
      <Box>
        <CustomDateCalendar
          loading={isLoading}
          minDate={moment()}
          maxDate={challenge.eventStart}
          value={challenge.deadline}
          handleChange={(date) => {
            challengeDispatch({
              type: "UPDATE_CHALLENGE",
              payload: { deadline: date },
            });
          }}
        />
        <Typography sx={{ color: "red" }}>
          {!moment(challenge.deadline).isValid()
            ? "Deadline is not valid"
            : !moment(challenge.deadline).isSameOrAfter(moment())
            ? "Deadline cannot be in the past"
            : !moment(challenge.deadline).isSameOrBefore(
                moment(challenge.eventStart)
              )
            ? "Deadline cannot be after the challenge start"
            : null}
        </Typography>
      </Box>
    ),
    disabled: ({ challenge }) =>
      !moment(challenge.deadline).isSameOrAfter(moment()) ||
      !moment(challenge.deadline).isSameOrBefore(moment(challenge.eventStart)),
    backFn: ({ handleStepChange }) =>
      handleStepChange(getStepIndexById("response")),
    secondaryFn: ({ handleStepChange, handleSubmit, challenge }) => {
      //wait for challenge to be created from the previous step
      if (challenge.linkCode !=null && challenge.linkCode.length>0){
        handleStepChange(getStepIndexById("share"));
        handleSubmit({ publish: true });
      }
    },
    nextFn: ({ handleStepChange }) =>
      handleStepChange(getStepIndexById("publishDate")),
    nextButtonText: "Publish later",
    secondaryButtonText: "Publish now",
  },
  {
    id: "publishDate",
    label: "What is the publish date?",
    disablePadding: true,
    required: true,
    Component: ({ isLoading, challenge, challengeDispatch }) => {
      const diff = moment(challenge.deadline).diff(
        moment(challenge.publishStart)
      );
      const duration = moment.duration(diff);
      //const hoursToFillIn = duration.asHours().floor();
      const msToFillIn = duration.asMilliseconds();
      const dayToFillIn = Number(moment.utc(msToFillIn).format("DD")) - 1;
      const hoursToFillIn = Number(moment.utc(msToFillIn).format("HH"));
      const minutesToFillIn = Number(moment.utc(msToFillIn).format("mm"));

      return (
        <Box>
          <CustomDateCalendar
            loading={isLoading}
            minDate={moment()}
            maxDate={challenge.eventStart}
            value={challenge.publishStart}
            handleChange={(date) => {
              challengeDispatch({
                type: "UPDATE_CHALLENGE",
                payload: { publishStart: date },
              });
            }}
          />
          <Typography>
            {!moment(challenge.publishStart).isValid()
              ? "Publish date is not valid"
              : !moment(challenge.publishStart).isSameOrAfter(moment())
              ? "Publish date cannot be in the past"
              : !moment(challenge.publishStart).isSameOrBefore(
                  moment(challenge.deadline)
                )
              ? "Publish date cannot be after the deadline"
              : moment(challenge.publishStart).isValid()
              ? `Other Cassandras will have ${dayToFillIn} days, ${hoursToFillIn} hours, and ${minutesToFillIn} minutes to answer your challenge`
              : null}
          </Typography>
        </Box>
      );
    },
    disabled: ({ challenge }) =>
      !moment(challenge.publishStart).isSameOrAfter(moment()) ||
      !moment(challenge.publishStart).isSameOrBefore(challenge.deadline),
    backFn: ({ handleStepChange }) =>
      handleStepChange(getStepIndexById("deadline")),
    nextFn: ({ handleStepChange, handleSubmit }) => {
      handleStepChange(getStepIndexById("share"));
      handleSubmit({ publish: false });
    },
    nextButtonText: "Publish",
  },
  {
    id: "share",
    label: "Share your challenge",
    Component: ({ challenge }) => {
      const url = `${process.env.REACT_APP_ROOT_PATH}${process.env.REACT_APP_BASE_PATH}/f/${challenge.linkCode}`;
      return (
        <Box>
          <TextField
            variant="standard"
            value={url}
            onChange={(event) => event.target.select()}
            fullWidth
            margin="none"
          />
          <Alert severity="success">Copied to clipboard</Alert>
        </Box>
      );
    },
    nextFn: ({ end }) => {
      end();
    },
    nextButtonText: "View my challenge",
  },
];

const getStepIndexById = (id) => steps.map((step) => step.id).indexOf(id);
