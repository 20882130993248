import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

const muiSliderColor = "#4098fe";
const muiSliderHeight = 20;
const muiSliderThumbHeight = 24;
const muiSliderLabelHeight = 24;

export const PrettoSlider = styled(Slider)(({ disableThumb }) => ({
  color: muiSliderColor,
  height: muiSliderHeight,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: disableThumb ? 0 : muiSliderThumbHeight,
    width: disableThumb ? 0 : muiSliderThumbHeight,
    backgroundColor: "#fff",
    border: `${disableThumb ? 0 : 2}px solid currentColor`,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: muiSliderLabelHeight,
    height: muiSliderLabelHeight,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: muiSliderColor,
    transformOrigin: "bottom left",
    transform: "translate(50%, -70%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -70%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));
