import React, { useContext } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Alert,
  Container,
  Button,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";

import { stringToHslColor } from "../Common/tools";
import { useFollow, useFollowerFollowing } from "../services/followers";
import AuthContext from "../authContext";
import ProfileDialog from "./ProfileDialog";
import FollowButton from "./FollowButton";

export default function Profile() {
  const navigate = useNavigate();
  const {
    login: { handle, isVerified },
  } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const { data: followersFollowing, refetch: refetchFollowersFollowing } =
    useFollowerFollowing();

  const tabs = [
    {
      id: "following",
      label: "Subscriptions",
      list: followersFollowing?.filter(({ isFollowing }) => isFollowing),
      empty: "You are not subscribed to anyone",
    },
    {
      id: "followers",
      label: "Subscribers",
      list: followersFollowing?.filter(({ isFollower }) => isFollower),
      empty: "You don't have any subscribers yet",
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box textAlign={"center"}>
        <AccountCircleIcon
          sx={{
            width: 128,
            height: 128,
            opacity: 0.4,
          }}
        />
        <Typography variant="h6">{handle}</Typography>
      </Box>
      <Container maxWidth="xs" sx={{ textAlign: "center", padding: 2 }}>
        {tabs[value].list != null && !isVerified ? (
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() =>
                  navigate("/verify", { state: { variant: "register" } })
                }
              >
                Click here
              </Button>
            }
          >
            Verify your email
          </Alert>
        ) : null}
        <Tabs value={value} onChange={handleChange} centered>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              label={`${tabs[index].list?.length ?? ""} ${tab.label}`}
              value={index}
            />
          ))}
        </Tabs>
        <Box padding={1} />
        <Box textAlign={"center"}>
          <ProfileDialog />
        </Box>
        {tabs[value].list == null ? <CircularProgress /> : null}
        {tabs[value].list && tabs[value].list.length > 0 ? (
          <List>
            {tabs[value].list.map((item) => (
              <ListItem key={item.id}>
                <ListItemAvatar>
                  <Avatar sx={{ background: stringToHslColor(item.handle) }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.handle}
                  secondary={item.followsYou ? "Subscribed to you" : null}
                />
                {tabs[value].id === "following" ? (
                  <ListItemSecondaryAction>
                    <FollowButton
                      follow={false}
                      handle={item.handle}
                      onSuccess={refetchFollowersFollowing}
                    />
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            ))}
          </List>
        ) : null}
        {tabs[value].list && tabs[value].list.length === 0 ? (
          <Box textAlign={"center"}>
            <NoAccountsOutlinedIcon
              sx={{
                width: 64,
                height: 64,
                opacity: 0.4,
              }}
            />
            <Typography>{tabs[value].empty}</Typography>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
}
