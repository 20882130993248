import React, { useState } from "react";
import {
  ButtonBase,
  Paper,
  Dialog,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import ProfileList from "./ProfileList";

export default function ProfileDialog() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <React.Fragment>
      <ButtonBase sx={{ borderRadius: 1 }} onClick={handleOpen}>
        <Paper
          sx={{
            p: "2px ",
            display: "flex",
            alignItems: "center",
            width: 300,
          }}
          elevation={2}
        >
          <Box sx={{ p: "10px" }} aria-label="menu">
            <SearchIcon />
          </Box>
          <Typography>Search on handle...</Typography>
        </Paper>
      </ButtonBase>
      <Dialog
        open={open}
        BackdropProps={{
          classes: {
            root: {
              backdropFilter: "blur(3px)",
              backgroundColor: "rgba(0,0,30,0.4)",
            },
          },
        }}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent style={{ textAlign: "center", height: "400px" }}>
          <ProfileList variant="list" handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
