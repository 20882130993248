import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Copyright from "./Copyright";
import { useForgetPass } from "../services/login";

const forgetPassSchema = z.object({
  email: z.string().nonempty("Email is required").email("Email is invalid"),
});

export default function ForgetPass() {
  const navigate = useNavigate();
  const {
    register,
    setError,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(forgetPassSchema),
  });

  const { mutate } = useForgetPass({
    onSuccess: (_, vars) =>
      navigate("/verify", {
        state: { variant: "forgetPass", email: vars.email },
      }),
  });
  const onSubmitHandler = (values) => {
    mutate(values);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recover password
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Don't worry, enter your email address below and we'll send you a
                link to reset your password.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={!!errors["email"]}
                helperText={errors["email"] ? errors["email"].message : ""}
                {...register("email")}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Email me a recovery link
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
