import { Button, Typography, Card, Box } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function EndOfFeed({
  text = "You completed all challenges",
  buttonText = "Next",
  onSubmit,
}) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box>
        <CheckCircleIcon
          sx={{ width: 150, height: 150, color: "primary.main" }}
        />
        <Typography variant="h6">{text}</Typography>
        <Box padding={2} />
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}
