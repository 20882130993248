import { useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { api, logging } from "./api";
import { getPublicForms } from "../models/challenge";
import AuthContext from "../authContext";

// return a list of responses
export const useResponses = (props) => {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useQuery(["responses", userId, login_expirationInterval], () =>
    api
      .post(`/getAnsweredFormList.php`, {
        login_userId: userId,
        login_expirationInterval,
      })
      .then((res) => {
        logging(res);
        onSuccess(res.data);
        return res.data;
      })
  );
};

// return a formList
export const useForm = (props) => {
  const { onSuccess = () => {}, selection = "answered" } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useQuery(["formList2", userId, login_expirationInterval], () =>
    api
      .post(`/getFormList2.php`, {
        selection,
        login_userId: userId,
        login_expirationInterval,
      })
      .then((res) => {
        logging(res);
        const status = res.data?.status ?? -1;
        if (status > 0) {
          const list = getPublicForms(res.data?.list ?? {});
          onSuccess(res.data);
          return list;
        } else {
          return [];
        }
      })
  );
};

// return a challenge
export function useChallenge(props) {
  const { onSuccess = () => {}, formId = -1, enabled = false } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  // enable query if formId is not -1
  return useQuery(
    ["form", formId, userId, login_expirationInterval],
    () =>
      api
        .post(`/getFormPublic_table.php`, {
          id: formId,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          onSuccess(res.data);
          return res.data;
        }),
    {
      enabled: enabled,
      cacheTime: 0,
    }
  );
}

// return a challenge
export function usePublicChallenge(props) {
  const {
    onSuccess = () => {},
    linkCode = "",
    enabled = false,
    minFollowingId,
  } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  // enable query if formId is not -1
  return useQuery(
    ["publicForm", linkCode, userId, login_expirationInterval],
    () =>
      api
        .post(`/getFormPublic_table.php`, {
          linkCode,
          minFollowingId,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          onSuccess(res.data);
          return res.data;
        }),
    {
      enabled: enabled,
      cacheTime: 0, // disable cache for public challenges
    }
  );
}

// create a new or update an existing challenge
export function useCreateChallenge(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (data) =>
      api
        .post(`/editForm.php`, {
          ...data,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return res.data;
        }),
    {
      onSuccess: (data, vars) => {
        onSuccess(data, vars);
      },
    }
  );
}

// post the outcome
export function useDecideForm(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (data) =>
      api
        .post(`/decideForm.php`, {
          ...data,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return res.data;
        }),
    {
      onSuccess: (data, vars) => {
        onSuccess(data, vars);
      },
    }
  );
}

// post the outcome
export function useDeleteFrom(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (data) =>
      api
        .post(`/deleteForm.php`, {
          ...data,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return res.data;
        }),
    {
      onSuccess: (data, vars) => {
        onSuccess(data, vars);
      },
    }
  );
}

export function useAnswerChallenge(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (data) =>
      api
        .post(`/answerChallenges.php`, {
          ...data,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return res.data;
        }),
    {
      onSuccess: (data, vars) => {
        onSuccess(data, vars);
      },
    }
  );
}

// return a formList
export const useFeed = (props) => {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useQuery(["feed", userId], () =>
    api
      .post(`/getFormFeed.php`, {
        login_userId: userId,
        login_expirationInterval,
      })
      .then(
        (res) => {
          logging(res);
          const status = res.data?.status ?? -1;
          if (status === 1) {
            const list = getPublicForms(res.data?.list ?? {});
            onSuccess?.(res.data);
            return list;
          } else {
            return [];
          }
        },
        { cacheTime: 0 }
      )
  );
};
