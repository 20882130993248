import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_CONTROLLER_PATH,
  //withCredentials: true,
});

export function logging(res) {
  const url = res?.config?.url ?? "unknown url";
  let post = res?.config?.data ? JSON.parse(res.config.data) : {};
  if ("password" in post) post.password = "********"; // hide password
  const data = res?.data ?? {};
  // redirect user to login page if not logged in
  if (
    data?.status === -1 &&
    !["/login.php", "/getUserSettings.php"].includes(url)
  ) {
    window.location = `${process.env.PUBLIC_URL}/login`;
  }
  console.log({ url, post, data });
}
