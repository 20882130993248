import * as React from "react";
import { Box } from "@mui/material";
import Badge from "@mui/material/Badge";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { TimeField } from "@mui/x-date-pickers/TimeField";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function ServerDay(props) {
  const { minDate, maxDate, day, outsideCurrentMonth, ...other } = props;
  const isSelected =
    !outsideCurrentMonth && day.isBetween(minDate, maxDate, "day", []);
  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? (
          <FiberManualRecordIcon
            fontSize="small"
            sx={{ color: "primary.light", transform: "scale(0.6)" }}
          />
        ) : undefined
      }
    >
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
      />
    </Badge>
  );
}

export default function CustomDateCalendar({
  minDate,
  maxDate,
  loading,
  value,
  handleChange,
}) {
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar
          value={value}
          onChange={handleChange}
          loading={loading}
          minDate={minDate}
          maxDate={maxDate}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              minDate,
              maxDate,
            },
          }}
        />
        <TimeField
          value={value}
          onChange={handleChange}
          format="HH:mm"
          sx={{ width: 275 }}
        />
      </LocalizationProvider>
    </Box>
  );
}
