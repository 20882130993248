import CustomList from "./CustomList";

export default function Leaderboard() {
  const bgColor1 = "rgb(242 212 95 / 15%)";
  const bgColor2 = "rgb(157 175 243 / 15%)";

  return (
    <div>
      <CustomList bgColor={bgColor2} />
    </div>
  );
}
