import { Box, Card, Paper, Typography } from "@mui/material";
import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import CircleIcon from "@mui/icons-material/Circle";

const backgroundColor='#4098fe';
const meColor='white';//'#08FF08';

function jitterFormat(data_in,range){
  var data=JSON.parse(JSON.stringify(data_in));

  setCircleDetails(data);

  //for (var c = 0; c < 10; c++) {
    var overlap=calcCircleOverlap(data,range);
    for (var i = 0; i < data.length; i++) {
      if (data[i].localOverlap>0){
        var minIndex=0;
        var minOverlap=overlap;
        for (var j = 0; j < 20; j++) {
          data[i].y=0.05*j;
          overlap=calcCircleOverlap(data,range);
          if (overlap<minOverlap){
            minOverlap=overlap;
            minIndex=j;
          }
        }
        data[i].y=0.05*minIndex;
        overlap=calcCircleOverlap(data,range);
      }
    }
  //}
  
  //translate up
  for (var i = 0; i < data.length; i++) {
    data[i].y=data[i].y+0.5;
  }

  return data
}

function calcCircleOverlap(data,range){
  const radiusFactor=(2/12);
  const ratio=10;
  var overlap=0;
  for (var i = 0; i < data.length; i++) {
    data[i].localOverlap=0;
  }
  for (var i = 0; i < data.length; i++) {
    for (var j = 0; j < i; j++) {
      var radius_i=data[i].radius*radiusFactor;
      var radius_j=data[j].radius*radiusFactor;
      var deltax=(100/range)*(data[i].x-data[j].x);
      var deltay=ratio*(data[i].y-data[j].y);
      var d=Math.sqrt(deltax*deltax+deltay*deltay);
      var localOverlap=Math.max(radius_j+radius_i-d,0);
      data[i].localOverlap=data[i].localOverlap+localOverlap;
      data[j].localOverlap=data[j].localOverlap+localOverlap;
      overlap=overlap+localOverlap;
    }
    data[i].localOverlap=data[i].localOverlap+Math.abs(data[i].y-0);
  }
  return overlap;
}

function setCircleDetails(data){
  for (var i = 0; i < data.length; i++) {
    data[i].color="#4098fe";
    data[i].stroke="white";
    data[i].edge="#4098fe";
    data[i].radius=8;

    //if (data[i].favorite){
      data[i].radius=12;
      data[i].initials=data[i].handle.substring(0,2);
    //}

    if (data[i].rank==1){
      data[i].color="#D4AF37";
      data[i].edge="white";
      data[i].stroke="black";
    }
    if (data[i].rank==2){
      data[i].color="#C0C0C0";
      data[i].edge="white";
      data[i].stroke="black";
    }
    if (data[i].rank==3){
      data[i].color="#CD7F32";
      data[i].edge="white";
      data[i].stroke="black";
    }

    if (data[i].isMe){
      if (data[i].rank<4){ 
        data[i].crownColor=data[i].color;
        data[i].initials="";
      }
      data[i].radius=14;
      data[i].color=meColor;
      data[i].edge=backgroundColor;
      data[i].stroke=backgroundColor;
    }
  }
}

function calcTicks(min,max){
  if (max==100 && min==0){
    return [0,25,50,75,100];
  }
  if (max-min<12){
    var ticks=[];
    for (var i = min; i <= max; i++) {
      ticks.push(i);
    }
    return ticks;
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    // Filter de payload om bepaalde waarden uit te sluiten
    const value = payload?.[0]?.payload?.x;
    const handle = payload?.[0]?.payload?.handle;
    return (
      <Card>
        <Box p={2}>
          <Typography>
            {handle}: {value}
          </Typography>
        </Box>
      </Card>
    );
  }

  return null;
};

const getCustomTickValue = (value,valueFormat) => {
  var tickValue=value;
  if (valueFormat.includes("<ABSVALUE>")){
    tickValue=Math.abs(tickValue)
  }
  return tickValue;
};

function CustomTick(props) {
  const { x, y, stroke, payload, valueFormat } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor='middle' x={0} y={0} dy={16} fill="#666">
        {getCustomTickValue(payload.value,valueFormat)}
      </text>
    </g>
  );
}

export default function CustomResponse({ min = 0, max = 100, data, variant, valueFormat }) {
  const RenderDot = (props) => {
    const { cx, cy, color, stroke, edge, initials, radius, crownColor, isMe, rank} = props;
    return (
      <svg>
        <circle cx={cx} cy={cy} fill={color} stroke={edge} r={radius}/>
        <text fill={stroke} fontWeight="normal" x={cx} y={cy+1} alignmentBaseline="middle" textAnchor="middle">{initials}</text>
        <svg x={cx-16} y={cy-8} >
        {(isMe && rank>0 && rank<4) ? (
          <path  transform="translate(7, 0) scale(0.14 0.14)"
            d="m 64.000012,31.484944 28.902346,36.127871 28.902242,-36.127871 -7.22553,65.030147 -101.158086,0 L 6.1954237,31.484944 35.0977,67.612815 64.000012,31.484944 z"
            stroke="grey" strokeWidth="10" fill={crownColor}
          />
        ):null}
        </svg>
      </svg>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={60}>
      <ScatterChart
        margin={{
          top: 5,
          right: 20,
          bottom: 5,
          left: 20,
        }}
      >
        <XAxis type="number" dataKey="x" name="x" unit="" domain={[min, max]} interval={0} ticks={[data[0].x]} tick={<CustomTick valueFormat={valueFormat}/>}/>
        <YAxis type="number" dataKey="y" name="y" unit="" domain={[0, 1]} hide={true} />
{/*
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ strokeDasharray: "3 3" }}
        />
*/}

        <Scatter
          data={jitterFormat(data,max-min)}
          shape={<RenderDot />}
          /*  shape={
            <CircleIcon sx={{ color: "#8884d8", width: 30, height: 30 }} />
  /        } */
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
