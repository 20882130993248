import React from "react";
import CustomSnackbar from "./CustomSnackbar";

const getSuccess = (mutation) => {
  const status = mutation.data?.status > 0;
  return status;
};

function MutationMessage({ mutation, text, vertical }) {
  return (
    <React.Fragment>
      {mutation.isLoading ? (
        <CustomSnackbar
          message={text?.loading ?? "Loading"}
          severity="info"
          vertical={vertical}
        />
      ) : (
        <React.Fragment>
          {mutation.isSuccess && getSuccess(mutation) ? (
            <CustomSnackbar
              message={text?.success ?? "Success"}
              severity="success"
              vertical={vertical}
            />
          ) : null}
          {(mutation.isSuccess && !getSuccess(mutation)) || mutation.isError ? (
            <CustomSnackbar
              message={text?.error ?? "Someting went wrong"}
              severity="error"
              vertical={vertical}
            />
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default MutationMessage;
