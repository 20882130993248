import { useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { getFollowers, getLeaderboardData } from "../models/followers";
import { api, logging } from "./api";
import { getFindUsers } from "../models/profile";
import AuthContext from "../authContext";

export function useLeaderboard(props) {
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  const { onSuccess = () => {}, challengerId, teams } = props ?? {};
  return useQuery(
    ["leaderboard", challengerId, teams, userId, login_expirationInterval],
    () =>
      api
        .post(`/getLeaderBoard.php`, {
          challengerId,
          teams,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          onSuccess(res.data);
          return getLeaderboardData(res.data);
        })
  );
}

export function useFollowers(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useQuery(["followers", userId, login_expirationInterval], () =>
    api
      .post(`/getFollowers.php`, {
        login_userId: userId,
        login_expirationInterval,
      })
      .then((res) => {
        logging(res);
        onSuccess(res.data);
        return getFollowers(res.data?.list);
      })
  );
}

export function useFollowing(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useQuery(["following", userId, login_expirationInterval], () =>
    api
      .post(`/getFollowing.php`, {
        login_userId: userId,
        login_expirationInterval,
      })
      .then((res) => {
        logging(res);
        onSuccess(res.data);
        return getFollowers(res.data?.list);
      })
  );
}

export function useFollowerFollowing() {
  let obj = {};
  const {
    data: followers,
    isLoading: followersLoading,
    refetch: refetchFollowers,
  } = useFollowers();
  const {
    data: following,
    isLoading: followingLoading,
    refetch: refetchFollowing,
  } = useFollowing();
  if (!!followers && !!following) {
    followers.forEach((follower) => {
      obj[follower.id] = {
        ...follower,
        isFollower: true,
        isFollowing: false,
        followsYou: true,
      };
    });
    following.forEach((following) => {
      if (following.id in obj) {
        obj[following.id].isFollowing = true;
      } else {
        obj[following.id] = {
          ...following,
          isFollower: false,
          isFollowing: true,
          followsYou: false,
        };
      }
    });
  }
  return {
    data: followers != null && following != null ? Object.values(obj) : null,
    isLoading: followersLoading || followingLoading,
    refetch: () => {
      refetchFollowers();
      refetchFollowing();
    },
  };
}

export function useFollow(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (data) =>
      api
        .post(`/followUser.php`, {
          ...data,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return res.data;
        }),
    {
      onSuccess: (data, vars) => {
        onSuccess(data, vars);
      },
    }
  );
}

export function useFavorite(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (data) =>
      api
        .post(`/setFavorite.php`, {
          ...data,
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return res.data;
        }),
    {
      onSuccess: (data, vars) => {        
        if (data.status==1){
          vars.pred.favorite=!vars.pred.favorite;
        }
        onSuccess(data, vars)
      },
    }
  );
}

export function useFindUser(props) {
  const { onSuccess = () => {} } = props ?? {};
  const {
    login: { userId, login_expirationInterval },
  } = useContext(AuthContext);
  return useMutation(
    (query) =>
      api
        .post(`/findUser.php`, {
          scope: query.scope,
          query: query.query ?? "",
          login_userId: userId,
          login_expirationInterval,
        })
        .then((res) => {
          logging(res);
          return getFindUsers(res.data);
        }),
    {
      onSuccess: (data, vars) => {
        onSuccess(data, vars);
      },
    }
  );
}
