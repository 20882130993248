import React from "react";
import { Box, Container } from "@mui/material";

import Toolbar from "./Toolbar";
import FormContent from "./FormContent";

export default function Response() {
  return (
    <Container maxWidth={"lg"} sx={{ bgColor: "#fafafa" }}>
      <Box paddingTop={1}>
        {/** Toolbar */}
        <Toolbar />
        <Box padding={1} />
        {/** Body */}
        <FormContent />
      </Box>
    </Container>
  );
}
