import React, { useState } from "react";

import FormContent from "../Form/FormContent";

export default function Carrousel({ challenges, handleEnd }) {
  const [page, setPage] = useState(0);

  const handleNextStep = () => {
    if (page < challenges.length - 1) {
      setPage(page + 1);
    } else {
      handleEnd();
    }
  };
  const currentChallenge = challenges?.[page];
  const linkCode = currentChallenge?.linkCode ?? "";
  const currentPage = page + 1;
  const maxPage = challenges.length;

  return (
    <FormContent
      linkCode={linkCode}
      onSubmit={handleNextStep}
      position={`${currentPage}/${maxPage}`}
    />
  );
}
