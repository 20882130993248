import { Box, Grid, Paper, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";

export default function Info({ bgColor, handle, isMine }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box>
      <Paper sx={{ bgcolor: bgColor, borderRadius: 2 }}>
        <Grid container padding={5}>
          <Grid item md={3}>
            <Box position="absolute" sx={{ marginTop: -10, marginLeft: 10 }}>
              {!isMobile ? (
                <img
                  src={`${process.env.PUBLIC_URL}/img/trophy-g859273ebb_1280.png`}
                  alt="Trophy"
                  style={{ rotate: "10deg", width: 160 }}
                />
              ) : null}
            </Box>
          </Grid>
          <Grid item md={9}>
            <Typography variant="h4">
              {isMine ? `Leaderboard of ${handle}` : `Leaderboard of ${handle}`}
            </Typography>
          </Grid>
        </Grid>
        <Box></Box>
      </Paper>
    </Box>
  );
}
