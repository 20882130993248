import { useMutation } from "react-query";
import { api, logging } from "./api";

export function useLogin(props) {
  const { onSuccess = () => {} } = props ?? {};
  return useMutation(
    (data) =>
      api.post(`/login.php`, data).then((res) => {
        logging(res);
        return res.data;
      }),
    {
      onSuccess: (data, vars) => onSuccess(data, vars),
    }
  );
}

export function useRegister(props) {
  const { onSuccess = () => {} } = props ?? {};
  return useMutation(
    (data) =>
      api.post(`/createUser.php`, data).then((res) => {
        logging(res);
        return res.data;
      }),
    { onSuccess: (data) => onSuccess(data) }
  );
}

// return settings
export function useSettings(props) {
  const { onSuccess = () => {} } = props ?? {};
  return useMutation(
    () =>
      api.post(`/getUserSettings.php`).then((res) => {
        logging(res);
        return res.data;
      }),
    {
      onSuccess: (data) => onSuccess(data),
    }
  );
}

export function useForgetPass(props) {
  const { onSuccess = () => {} } = props ?? {};
  return useMutation(
    (data) =>
      api.post(`/requestPasswordReset.php`, data).then((res) => {
        logging(res);
        return res.data;
      }),
    { onSuccess: (data, vars) => onSuccess(data, vars) }
  );
}

export function useChangePass(props) {
  const { onSuccess = () => {} } = props ?? {};
  return useMutation(
    (data) =>
      api.post(`/resetPassword.php`, data).then((res) => {
        logging(res);
        return res.data;
      }),
    { onSuccess: (data, vars) => onSuccess(data, vars) }
  );
}
