import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function CookiePolicyDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /*   const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]); */

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen("paper")}>Cookie Policy</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Cookie Policy</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          ></DialogContentText>
          <p>
            Please read this cookie policy (“cookie policy”, "policy") carefully
            before using Cassandras.be website (“website”, "service") operated
            by SV ("us", 'we", "our").
          </p>
          <b>What are cookies? </b>
          <p>
            Cookies are simple text files that are stored on your computer or
            mobile device by a website’s server. Each cookie is unique to your
            web browser. It will contain some anonymous information such as a
            unique identifier, website’s domain name, and some digits and
            numbers.{" "}
          </p>
          <b>What types of cookies do we use?</b>
          <p></p>
          <i>Necessary cookies</i>
          <p>
            {" "}
            Necessary cookies allow us to offer you the best possible experience
            when accessing and navigating through our website and using its
            features. For example, these cookies let us recognize that you have
            created an account and have logged into that account.{" "}
          </p>
          <table class="styles-table-52073e">
            <thead class="styles-thead-b5387c">
              <tr class="styles-tr-8bf670">
                <th class="styles-th-647960">cookie name</th>
                <th class="styles-th-647960"></th>
                <th class="styles-th-647960">description</th>
              </tr>
            </thead>
            <tbody class="styles-tbody-0db610">
              <tr class="styles-root-d8a5d0">
                <td class="styles-td-d9ff43">
                  <div class="styles-name-64ba99 t-cookieDetailLink">
                    PHPSESSID
                  </div>
                </td>
                <td class="styles-td-d9ff43"></td>
                <td class="styles-td-d9ff43">
                  Cookie generated based on the PHP language maintaining a
                  logged-in status for a user between pages.
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <br></br>
          <i> Functionality cookies</i>{" "}
          <p>
            {" "}
            Functionality cookies let us operate the site in accordance with the
            choices you make. For example, we will recognize your username and
            remember how you customized the site during future visits.
          </p>{" "}
          <i>Analytical cookies</i>
          <p>
            These cookies enable us and third-party services to collect
            aggregated data for statistical purposes on how our visitors use the
            website. These cookies do not contain personal information such as
            names and email addresses and are used to help us improve your user
            experience of the website.{" "}
          </p>
          <b> How to delete cookies? </b>
          <p>
            If you want to restrict or block the cookies that are set by our
            website, you can do so through your browser setting. Alternatively,
            you can visit{" "}
            <a target="_blank" href="http://www.internetcookies.com">
              www.internetcookies.com
            </a>
            , which contains comprehensive information on how to do this on a
            wide variety of browsers and devices. You will find general
            information about cookies and details on how to delete cookies from
            your device.
          </p>
          <b>Contacting us</b>
          <p>
            If you have any questions about this policy or our use of cookies,
            please contact us.
          </p>
          <i>Cookie policy template by WebsitePolicies.com</i>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
