import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  List,
  Box,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useLeaderboard } from "../services/followers";
import CustomListItem from "./CustomListItem";
import AuthContext from "../authContext";
import Info from "./Info";

export default function CustomList({ bgColor }) {
  const [showFollowing, setShowFollowing] = useState(false);
  const { challengerId } = useParams();
  const {
    login: { userId },
  } = useContext(AuthContext);

  const handleFollowing = (event) => setShowFollowing(event.target.checked);

  const { data, refetch: refetchLeaderBoard } = useLeaderboard({
    challengerId: challengerId === "0" ? userId : Number(challengerId),
    teams: true,
  });
  const followingList = data?.list.filter(
    (leaderboard) => leaderboard.following
  );
  const list = showFollowing ? followingList : data?.list;

  return (
    <React.Fragment>
      {data ? (
        <Box>
          {/*
          <Box>
            I'm in team {data.myTeamHandle}
          </Box>
          */}
          <Info bgColor={bgColor} handle={data.handle} isMine={challengerId === "0"} />
          {/*
          <Box textAlign="end">
            <FormControlLabel
              control={
                <Switch checked={showFollowing} onChange={handleFollowing} />
              }
              label="Show following"
            />
          </Box>
          */}
          <List>
            {list.length > 0 ? (
              list.map((item) => (
                <Box
                  key={item.id}
                  marginTop={2}
                  marginBottom={2}
                  boxShadow={1}
                  borderRadius={2}
                  bgcolor={bgColor}
                >
                  <CustomListItem item={item} refetchLeaderBoard={refetchLeaderBoard}/>
                </Box>
              ))
            ) : (
              <Typography>There is no leaderboard yet</Typography>
            )}
          </List>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </React.Fragment>
  );
}
