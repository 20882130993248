import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";

import styled from "@emotion/styled";

import { PrettoSlider } from "./PrettoSlider";
import { LightTooltip } from "../Common/LightTooltip";
import Arrow from "./Arrow";
import { CUSTOM_ANCHORS, DEFAULT_TYPE } from "./types";
import UserLabel from "./UserLabel";
import { useContext } from "react";
import AuthContext from "../authContext";

const backgroundColor='#4098fe';
const meColor='white';//'#08FF08';

const getScore = ({ outcome, ll }) => {
  const brierScore = Math.pow(outcome - ll / 100, 2);
  const score = 100 - 100 * brierScore;
  return Math.round(score);
};

const getText = ({ outcome, llhood, mean }) => {
  const mBrier = getScore({ outcome, ll: mean });
  const score = getScore({ outcome, ll: llhood }) - mBrier;
  const text = score < 0 ? "Potential losses" : "Potential gains";
  const operator = score < 0 ? "-" : "+";
  const absScore = Math.abs(score);
  const color = score < 0 ? "red" : "green";
  return { operator, absScore, color, text };
};

function DisplayScores({ llhood, mean }) {
  // very likely
  const mBrier1 = getScore({ outcome: 1, ll: mean });
  const score1 = getScore({ outcome: 1, ll: llhood }) - mBrier1;
  const text1 = score1 < 0 ? "Potential losses" : "Potential gains";
  const operator1 = score1 < 0 ? "-" : "+";
  const absScore1 = Math.abs(score1);
  const color1 = score1 < 0 ? "red" : "green";
  const brierScore1 = getText({ outcome: 1, llhood, mean });

  // very unlikely
  const mBrier2 = getScore({ outcome: 0, ll: mean });
  const score2 = getScore({ outcome: 0, ll: llhood }) - mBrier2;
  const text2 = score2 >= 0 ? "Potential gains" : "Potential losses";
  const operator2 = score2 >= 0 ? "+" : "-";
  const absScore2 = Math.abs(score2);
  const color2 = score2 >= 0 ? "green" : "red";
  const brierScore0 = getText({ outcome: 0, llhood, mean });

  return (
    <Box display={"flex"} alignItems="center" gap={2}>
      <Arrow scale={-1} />
      <Box width={"100%"}>
        <Typography variant="body2">
          {text1}: {mBrier1}{" "}
          <span style={{ color: color1, fontWeight: 800 }}>
            {operator1} {absScore1}
          </span>{" "}
          XP
        </Typography>
        <Typography variant="body2">
          {text2}: {mBrier2}{" "}
          <span style={{ color: color2, fontWeight: 800 }}>
            {operator2} {absScore2}
          </span>{" "}
          XP
        </Typography>
      </Box>
      <Arrow scale={1} />
    </Box>
  );
}

function ValueLabelComponent(props) {
  const {
    children,
    value,
    ownerState: {
      slotProps: { mean },
    },
  } = props;
  return (
    <LightTooltip
      enterTouchDelay={0}
      placement="top"
      title={<DisplayScores llhood={value} mean={mean} />}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 290,
        },
      }}
    >
      {children}
    </LightTooltip>
  );
}

function EditAnchor({ value, handleChange, w, placeholder }) {
  return (
    <Box width={w}>
      <TextField
        sx={{ width: "100%" }}
        variant="standard"
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        margin="none"
        placeholder={placeholder}
      />
    </Box>
  );
}

function FixedAnchor({ type, outcomeBinary, value, anchor, answerValue, minValue, maxValue, disabled }) {
  //const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMobile = false;
  const label = type === CUSTOM_ANCHORS ? `${anchor} (${value})` : anchor;

  var fontWeight='';
  var fontColor='grey';
  var distance=1-Math.abs(answerValue-value)/(maxValue-minValue);
  if (distance>0.5){
    fontWeight='bold';
    if (!disabled){
      fontColor=backgroundColor;
    }
  }

  return (
    <Box display="flex" gap={1} alignItems={"center"}>
      {type === "right" && outcomeBinary === 1 ? (
        <CheckCircleIcon sx={{ color: "green" }} />
      ) : null}
      <Typography variant="body2" sx={{fontWeight: fontWeight, color: fontColor, display: !isMobile ? "flex" : "none" }}>
        {label}
      </Typography>
      {type === "left" && outcomeBinary === 0 ? (
        <CheckCircleIcon sx={{ color: "green" }} />
      ) : null}
    </Box>
  );
}

const getCustomTickValue = (value,valueFormat) => {
  var tickValue=value;
  if (valueFormat.includes("<ABSVALUE>")){
    tickValue=Math.abs(tickValue)
  }
  return tickValue;
};

function Slikert({
  mode, // "edit", "decide"
  value,
  minValue,
  maxValue,
  minAnchor,
  maxAnchor,
  challengeDispatch,
  mean,
  tooltip,
  outcomeBinary,
  customAnchors,
  valueFormat,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const valueString=`${value}%`;
  const getValue = () => {
    if (value < 20) return valueString +" (very unlikely)";
    if (value >= 20 && value < 40) return valueString +" (unlikely)";
    if (value >= 40 && value < 60) return valueString + " (unsure)";
    if (value >= 60 && value < 80) return valueString + " (likely)";
    if (value >= 80) return valueString + " (very likely)";
  };
  const getCustomAnchorsValue = () => {
    let format = `${valueFormat}`;
    format = format.replace("<ABSVALUE>", `${Math.abs(value)}`);
    format = format.replace("<VALUE>", `${value}`);
    format = format.replace(
      "<CLOSESTANCHOR>",
      value <= (Number(minValue) + Number(maxValue)) / 2 ? minAnchor : maxAnchor
    );
    if (format === "") format = `${value}`;
    return format;
  };
  const gainLossesOutcome0 = getText({ outcome: 0, llhood: value, mean });
  const gainLossesOutcome1 = getText({ outcome: 1, llhood: value, mean });

  return (
    <Box display="flex" alignItems={"end"} justifyContent={"space-between"}>
      {mode === "edit" && customAnchors ? (
        <EditAnchor
          value={minValue}
          handleChange={(value) =>
            challengeDispatch({
              type: "UPDATE_CHALLENGE",
              payload: { minValue: value },
            })
          }
          w={50}
        />
      ) : !customAnchors ? (
        /*
        <Box
          visibility={tooltip && outcomeBinary === null ? "visible" : "hidden"}
        >
          <Typography variant="body2">On no</Typography>
          <Typography variant="body1" sx={{ color: gainLossesOutcome0.color }}>
            {`${gainLossesOutcome0.operator}${gainLossesOutcome0.absScore}XP`}
          </Typography>
        </Box>
        */
        <Box />
      ) : (
        <Box />
      )}

      <Box>
        <Typography variant={isMobile ? "subtitle2" : "h6"}>
          {customAnchors ? getCustomAnchorsValue() : getValue()}
        </Typography>
        {mode === "edit" && customAnchors ? (
          <EditAnchor
            value={valueFormat}
            handleChange={(value) =>
              challengeDispatch({
                type: "UPDATE_CHALLENGE",
                payload: { settings: { valueFormat: value } },
              })
            }
            w={350}
            placeholder={"<VALUE>, <ABSVALUE>, <CLOSESTANCHOR>"}
          />
        ) : null}
      </Box>

      {mode === "edit" && customAnchors ? (
        <EditAnchor
          value={maxValue}
          handleChange={(value) =>
            challengeDispatch({
              type: "UPDATE_CHALLENGE",
              payload: { maxValue: value },
            })
          }
          w={50}
        />
      ) : !customAnchors ? (
        /*
        <Box
          visibility={tooltip && outcomeBinary === null ? "visible" : "hidden"}
        >
          <Typography variant="body2">On yes</Typography>
          <Typography variant="body1" sx={{ color: gainLossesOutcome1.color }}>
            {`${gainLossesOutcome1.operator}${gainLossesOutcome1.absScore}XP`}
          </Typography>
        </Box>
        */
        <Box />
      ) : (
        <Box />
      )}
    </Box>
  );
}

export default function CustomSlider({
  handleValueChange,
  valueKey = "myLastValue",
  disabled = false,
  challenge,
  mean,
  tooltip = true,
  mode = "",
  challengeDispatch = () => {},
  refetchPublicChallenge = () => {},
}) {
  var StyleWrapper = styled.div``;
  if (mode==""){
    var content='';
    if (challenge.myLocalWeekRank>0 && challenge.myLocalWeekRank<4){
      content=`url('${process.env.PUBLIC_URL}/img/crown_${challenge.myLocalWeekRank}.svg')`
      StyleWrapper = styled.div`
      .MuiSlider-thumb::after{
        top: 95%;
        content: ${content};
      }  
      `;
    }else if (challenge.isExpired==false && 1==0){
      var content="'⍝'";
      StyleWrapper = styled.div`
      .MuiSlider-thumb::after{
        top: 75%;
        content: ${content};
        font-size: 1.25em;
        font-weight: bold
      }  
      `;
    }
  }
  const ref = useRef();
  const { login } = useContext(AuthContext);
  const getMarks = () => {
    if (!challenge.otherPredictions) return [];
    const otherPredictions =
      challenge.Npredictors === 1
        ? challenge.otherPredictions.filter((pred) => pred.type !== "stat")
        : challenge.otherPredictions;
    return otherPredictions.map((pred, i) => ({
      value: pred.lastValue,
      label: (
        <UserLabel
          name={pred.handle}
          height={(otherPredictions.length - i) * 40}
          isCreator={challenge.ownerId === pred.id}
          isMyLastValue={pred.handle === login.handle}
          value={pred.lastValue}
          score={pred.score}
          showScore={challenge.isAdjudicated}
          weekRank={pred.weekRank}
          following={pred.following}
          refetch={refetchPublicChallenge}
        />
      ),
      name: "mark",
    }));
  };
  const marks = getMarks();

  const [customAnchors, setCustomAnchors] = useState(
    challenge.challengeType === CUSTOM_ANCHORS
  );

  useEffect(() => {
    setCustomAnchors(challenge.challengeType);
  }, [challenge]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleCustomAnchors = (event) => {
    if (event.target.checked) {
      // default challenge type
      challengeDispatch({
        type: "UPDATE_CHALLENGE",
        payload: { challengeType: CUSTOM_ANCHORS },
      });
    } else {
      challengeDispatch({
        type: "UPDATE_CHALLENGE",
        payload: { challengeType: DEFAULT_TYPE, minValue: 0, maxValue: 100 },
      });
    }
    setCustomAnchors(event.target.checked);
  };
  const isEmptySlider =
    disabled &&
    challenge?.otherPredictions?.filter((pred) => pred.handle === login.handle)
      .length === 0;
  const getTrack = () => {
    if (isEmptySlider) return false;
    if (challenge.challengeType === DEFAULT_TYPE) return "normal";
    return false;
  };

  var allMarks=[];

  if (challenge.otherPredictions != null) {
    var localChallengeRank=0;
    for (const otherPrediction of challenge.otherPredictions) {
      const rank = otherPrediction.localWeekRank;
      localChallengeRank = localChallengeRank+1;
      const favorite = otherPrediction.favorite;
      const value = otherPrediction.lastValue;
      console.log(rank);
      if (otherPrediction.id != login.userId && otherPrediction.id > null && ((localChallengeRank<4) || (rank<4 && rank!==null) || favorite)) {
        const initials = otherPrediction.handle.substring(0, 2);
        var stroke = "#4098fe";
        var color = "white";
        var crownOffset=-100;
        if (rank == 1) {
          color = "#D4AF37";
          stroke = "white";
          crownOffset= 3;
        }
        if (rank == 2) {
          color = "#C0C0C0";
          stroke = "white";
          crownOffset = 6;
        }
        if (rank == 3) {
          color = "#CD7F32";
          stroke = "white";
          crownOffset = 9;
        }
        const mark = {
          value: value,
          rank: rank,
          label: 
/*        
          <Box style={{ position: "absolute", top: -20, left: -15 }}>
            <svg width={30} height={50}>
              <circle cx={15} cy={13} fill={color} stroke={stroke} strokeWidth={1.5} r={7} />
              <rect width={30} height={50} x={0} y={30} rx={0} ry={0} fill="white" />
              <text fontWeight="normal" x={"50%"} y={40} width={100} alignmentBaseline="middle" textAnchor="middle">{initials}</text>
            </svg>
          </Box>
*/
          <Box style={{ position: "absolute", top: -45, left: -15 }}>
            <svg width={30} height={70}>
              <path  transform={"translate(7, " + crownOffset + ") scale(0.13 0.13)"}
                d="m 64.000012,31.484944 28.902346,36.127871 28.902242,-36.127871 -7.22553,65.030147 -101.158086,0 L 6.1954237,31.484944 35.0977,67.612815 64.000012,31.484944 z"
                fill={color} stroke={stroke}
              />
              <circle cx={15} cy={35} fill={color} stroke={stroke} strokeWidth={1.5} r={13} />
              <text fontWeight="normal" x={"50%"} y={36} width={100} alignmentBaseline="middle" textAnchor="middle">{initials}</text>
              <rect width={30} height={50} x={0} y={50} rx={0} ry={0} fill="white" />
              <text fontWeight="normal" x={"50%"} y={"85%"} width={100} alignmentBaseline="middle" textAnchor="middle">{getCustomTickValue(value,challenge.settings.valueFormat)}</text>
            </svg>
          </Box>
        };
        allMarks.push(mark);
      }
    }
  }

  //sort allMarks based on value
  allMarks.sort(function(a, b) { 
    return a.rank - b.rank;
  })

  // Get text width before rendering
  const getTextWidth = (text, font) => {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  }

  if (challenge.outcomeValue!=null){
    const value=challenge.outcomeValue;
    const thisText='✔ ' + getCustomTickValue(value,challenge.settings.valueFormat);
    const textWidth=(getTextWidth(thisText,'1em serif'));
    const boxWidth=textWidth+20;
    const outcomeMark={
      value: value,
      label: <Box style={{position:"absolute", top:7, left:-boxWidth/2}}>
              {//<CheckIcon style={{position:"absolute", top: 4, left: 5}} fontSize={"small"}/>
              }
              <svg width={boxWidth} height={90}>
                {//<circle cx={15} cy={13} fill={"white"} stroke={"green"} strokeWidth={1.5} r={11}/>
                }
                <rect width={boxWidth-2} height={25} x={1} y={1} rx={10} ry={10} stroke={"green"} fill={"green"} strokeWidth={1.5}/>
                <text fontSize="1em" fontWeight="500" x={"50%"} y={15.5} fill="white" alignmentBaseline="middle" textAnchor="middle">{thisText}</text>
              </svg>
            </Box>
    };
    allMarks.push(outcomeMark);
  }

  const [localMem, setLocalMem] = React.useState({ myLastValue: challenge.myLastValue });

  return (
    <Box>
      <Stack spacing={2} direction="row" alignItems="center">
        {mode === "edit" && customAnchors ? (
          <EditAnchor
            value={challenge.minAnchor}
            handleChange={(value) =>
              challengeDispatch({
                type: "UPDATE_CHALLENGE",
                payload: { minAnchor: value },
              })
            }
          />
        ) : (
          <FixedAnchor
            value={challenge.minValue}
            anchor={challenge.minAnchor}
            type={"left"}
            outcomeBinary={challenge.outcomeBinary}
            answerValue={challenge.myLastValue}
            minValue={challenge.minValue}
            maxValue={challenge.maxValue}
            disabled={disabled}
          />
        )}
        <Box width="100%" textAlign={"center"}>
          {/*
          <Slikert
            mode={mode}
            minValue={challenge.minValue}
            maxValue={challenge.maxValue}
            minAnchor={challenge.minAnchor}
            maxAnchor={challenge.maxAnchor}
            value={challenge[valueKey]}
            mean={mean}
            tooltip={tooltip}
            outcomeBinary={challenge.outcomeBinary}
            challengeDispatch={challengeDispatch}
            customAnchors={customAnchors}
            valueFormat={challenge.settings.valueFormat}
          />
        */}
        </Box>
        {mode === "edit" && customAnchors ? (
          <EditAnchor
            value={challenge.maxAnchor}
            handleChange={(value) =>
              challengeDispatch({
                type: "UPDATE_CHALLENGE",
                payload: { maxAnchor: value },
              })
            }
          />
        ) : (
          <FixedAnchor
            value={challenge.maxValue}
            anchor={challenge.maxAnchor}
            type={"right"}
            outcomeBinary={challenge.outcomeBinary}
            answerValue={challenge.myLastValue}
            minValue={challenge.minValue}
            maxValue={challenge.maxValue}
            disabled={disabled}
          />
        )}
      </Stack>
      <Stack spacing={2} direction="row" alignItems="center">
        <Box width="100%" textAlign={"center"}>
        <StyleWrapper>
          <PrettoSlider
            disabled={disabled}
            marks={allMarks}
            ref={ref}
            min={Number(challenge.minValue)}
            max={Number(challenge.maxValue)}

            //valueLabelDisplay={!isMobile ? "off" : "off"}
            valueLabelFormat={function (value){return getCustomTickValue(value,challenge.settings.valueFormat)}}
            valueLabelDisplay="on"
            aria-label="pretto slider"
            defaultValue={Math.round(
              //(Number(challenge.minValue) + Number(challenge.maxValue)) / 2
              challenge[valueKey]
            )}
            //marks={mode === "preview" ? null : marks}
            //value={challenge[valueKey]}
            
            onChange={(event, newValue) => {
              console.log(event);
              const sliderHeight =
                ref?.current?.offsetTop + ref?.current?.offsetHeight;
              //if (sliderHeight && event?.clientY > sliderHeight)
              //return console.log("User label clicked");
              if (disabled) return console.log("Challenge locked");
              //remove crown XXX
              localMem.myLastValue=newValue;
            }}

            onTouchEnd={(event) => {
              console.log(localMem.myLastValue);
              return handleValueChange(localMem.myLastValue);
            }}

            onMouseUp={(event) => {
              console.log(localMem.myLastValue);
              return handleValueChange(localMem.myLastValue);
            }}

            onMouseLeave={(event) => {
              console.log(localMem.myLastValue);
              return handleValueChange(localMem.myLastValue);
            }}

            /*
            slotProps={{ mean }}
            slots={
              tooltip && !isMobile
                ? {
                    valueLabel: ValueLabelComponent,
                  }
                : null
            }
            */

            //track={getTrack()}
            track={false}
            disableThumb={isEmptySlider}
            sx={disabled ? {
              '& .MuiSlider-thumb': {
                  color: "grey",
                  width: "32px",
                  height: "32px",
                  backgroundColor: meColor
                },
              '& .MuiSlider-track': {
                  color: "grey"
              },
              '& .MuiSlider-rail': {
                  color: "grey"
              },
              '& .MuiSlider-active': {
                  color: "grey"
              },
              '& .MuiSlider-markLabel': {
                top: "40px"
              },
              '&': {
                padding: "20px 0"
              }              
            }:{
              '& .MuiSlider-thumb': {
                width: "32px",
                height: "32px",
                backgroundColor: meColor,
                animation: 'ripple 1.2s infinite ease-in-out',
              },
              '@keyframes ripple': {
                '80%': {
                  transform: 'translate(-16px,-16px) scale(0.92)',
                  opacity: 1,
                },
                '110%': {
                  transform: 'translate(-16px,-16px) scale(1)',
                  opacity: 1,
                },

              },
              '& .MuiSlider-markLabel': {
                top: "40px"
              },
              '&': {
                padding: "20px 0"
              }
            }}
          />
          </StyleWrapper>
          <Box padding={0} />
        </Box>
      </Stack>
      {mode === "edit" ? (
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(customAnchors)}
              onChange={handleCustomAnchors}
            />
          }
          label="Custom anchors"
        />
      ) : null}
    </Box>
  );
}
