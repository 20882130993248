import { Link } from "react-router-dom";
import { Typography, Grid, Button } from "@mui/material";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useContext } from "react";
import AuthContext from "../authContext";

export default function Toolbar({ responseList }) {
  const {
    login: { creatorRights },
  } = useContext(AuthContext);
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h5">Latest Responses</Typography>
        {responseList ? (
          <Typography variant="body2">
            {responseList.length === 1
              ? `${responseList.length} responses`
              : `${responseList.length} responses`}
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={12} md={6} textAlign={{ xs: "start", md: "end" }}>
        {creatorRights !== 0 ? (
          <Button
            component={Link}
            to="/create"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            size="large"
            variant="contained"
          >
            Create a challenge
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
