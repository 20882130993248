import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import SendRoundedIcon from "@mui/icons-material/SendRounded";

import Copyright from "./Copyright";
import { useRegister } from "../services/login";
import AuthContext from "../authContext";

const registerSchema = z.object({
  handle: z
    .string()
    .nonempty("Handle is required")
    .max(15, "Handle must be less than 16 characters"),
  email: z.string().nonempty("Email is required").email("Email is invalid"),
  password: z
    .string()
    .nonempty("Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
  //passwordConfirm: z.string().nonempty("Please confirm your password"),
  /* terms: z.literal(marketing, {
      invalid_type_error: "Accept Terms is required",
    }), */
});
/*  .refine((data) => data.password === data.passwordConfirm, {
    path: ["passwordConfirm"],
    message: "Passwords do not match",
  }); */

export default function Register({ handleMode = () => {} }) {
  const navigate = useNavigate();
  const {
    register,
    setError,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(registerSchema),
  });
  const { onLogin, login } = useContext(AuthContext);
  const isSubmit = login.variant === "submit";

  const [searchParams, setSearchParams] = useSearchParams();
  const setEmail = searchParams.get("setEmail") ?? "";
  var disableEmail = false;
  if (setEmail != ""){
    disableEmail = true;
  }
  console.log(setEmail);

  // submit the registration
  const registerMutation = useRegister({
    onSuccess: (data) => {
      const status = data?.status ?? 0;
      switch (status) {
        case 0:
          // could not create account
          setError("handle", { type: "server", message: "" });
          setError("email", { type: "server", message: "" });
          setError("password", {
            type: "server",
            message: "Could not create an account",
          });
          break;
        case 1:
          // succesful registeration
          onLogin();
          break;
        case 2:
          // email with account already exists
          setError("email", {
            type: "server",
            message: "Account with that email already exists and has been verified",
          });
          break;
        case 3:
          setError("handle", {
            type: "server",
            message: "Handle aready exists",
          });
          break;
        case 4:
          setError("email", {
            type: "server",
            message: "Account with that email already exists but has not yet been verified",
          });
          break;
        case 5:
          setError("email", {
            type: "server",
            message: "Email has not been approved for registration",
          });
          break;
        default:
          setError("unknown", {
            type: "unknown",
            message: "Unknwown error",
          });
          break;
      }
    },
  });
  const onSubmitHandler = (values) => registerMutation.mutate(values);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box marginTop={!isSubmit ? 8 : 1} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          mb: !isSubmit ? null : 2
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register to Cassandras
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="nicknames"
                name="handle"
                required
                fullWidth
                id="handle"
                label="Nickname"
                autoFocus
                error={!!errors["handle"]}
                helperText={errors["handle"] ? errors["handle"].message : ""}
                {...register("handle")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                defaultValue={setEmail}
                inputProps={
                  { readOnly: disableEmail}
                }
                autoComplete="email"
                error={!!errors["email"]}
                helperText={errors["email"] ? errors["email"].message : ""}
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!errors["password"]}
                helperText={
                  errors["password"] ? errors["password"].message : ""
                }
                {...register("password")}
              />
            </Grid>
            {/*  <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  {...register("marketing")}
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label={
                    <Typography
                      color={errors["marketing"] ? "error" : "inherit"}
                    >
                      I want to receive inspiration, marketing promotions and
                      updates via email.{" "}
                    </Typography>
                  }
                />
                <FormHelperText error={!!errors["marketing"]}>
                  {errors["marketing"] ? errors["marketing"].message : ""}
                </FormHelperText>
              </FormGroup>
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            startIcon={!isSubmit ? null : <SendRoundedIcon />}
          >
            {!isSubmit ? "Register" : "Register and submit"}
          </Button>

          <Box padding={1} />

          <Divider />

          <Box padding={1} />

          <Button
            onClick={
              !isSubmit ? () => navigate("/login") : () => handleMode("login")
            }
            sx={[
              {
                "&:hover": {
                  backgroundColor: "#00695f",
                },
              },
              { backgroundColor: "#009688" },
            ]}
            variant="contained"
          >
            Login with existing account
          </Button>
        </Box>
      </Box>

      {!isSubmit ? <Copyright sx={{ mt: 5 }} /> : null}
    </Container>
  );
}
