import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import GavelIcon from "@mui/icons-material/Gavel";

import { useDecideForm } from "../services/challenges";
import MobileDesktopButton from "../Common/MobileDesktopButton";
import CustomSlider from "./CustomSlider";
import { challengeReducer } from "../Create/challengeReducer";
import { CUSTOM_ANCHORS } from "./types";

export default function DecideDialog({
  formId,
  challenge: initChallenge,
  refetch,
}) {
  const [open, setOpen] = React.useState(false);
  const [challenge, challengeDispatch] = React.useReducer(
    challengeReducer,
    initChallenge
  );

  const handleChallenge = (obj) =>
    challengeDispatch({ type: "UPDATE_CHALLENGE", payload: obj });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveMutation = useDecideForm({
    onSuccess: (data) => {
      if (data?.status === 1) {
        if (data?.status === 1) {
          // close the dialog
          handleClose();
          refetch(); // refetch the form
        }
      }
    },
  });

  const outcomeValue = challenge?.outcomeValue ?? challenge.myLastValue;
  return (
    <div>
      <MobileDesktopButton
        startIcon={<GavelIcon />}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Decide
      </MobileDesktopButton>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {challenge.challengeType === CUSTOM_ANCHORS
            ? "What is the outcome value?"
            : "Did the statement happen?"}
        </DialogTitle>
        <DialogContent>
          {challenge.challengeType === CUSTOM_ANCHORS ? (
            <CustomSlider
              mode="decide"
              challenge={{
                ...challenge,
                outcomeValue: outcomeValue,
              }}
              handleValueChange={(newValue) =>
                challengeDispatch({
                  type: "UPDATE_CHALLENGE",
                  payload: { outcomeValue: newValue },
                })
              }
              valueKey="outcomeValue"
              challengeDispatch={challengeDispatch}
              mode="preview"
            />
          ) : (
            <Box sx={{ width: "100%" }}>
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={challenge.outcomeBinary}
                  onChange={(event) =>
                    challengeDispatch({
                      type: "UPDATE_CHALLENGE",
                      payload: {
                        outcomeBinary: Number(event.target.value),
                      },
                    })
                  }
                >
                  <FormControlLabel value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              saveMutation.mutate({
                formId,
                challengesJSON: JSON.stringify([
                  {
                    id: challenge.id,
                    outcomeBinary: challenge.outcomeBinary,
                    outcomeValue: outcomeValue,
                  },
                ]),
              })
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
