class Profile {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? Number(obj.id) : -1;
    this.handle = obj.handle != null ? obj.handle : "";
    this.following = obj.following === 1 ? 1 : 0;
  }
}

export function getFindUsers(findUsers) {
  //console.log("Creating users list");
  const userList = findUsers?.list ?? [];
  const keys = Object.keys(userList);
  const list = keys.map((key) => new Profile({ ...userList[key], id: key }));
  //console.log(list);
  return list;
}
