import { Box, Typography, Link } from "@mui/material";
import { useContext, useState } from "react";
import AuthContext from "../authContext";
import CustomizedDialogs from "../Common/CustomizedDialogs";
import Register from "../Login.js/Register";
import Login from "../Login.js";

export default function SubmitDialog({ open, handleClose }) {
  const { login } = useContext(AuthContext);
  const [mode, setMode] = useState("");
  const handleMode = (m) => setMode(m); // toggle between login and register
  return (
    <CustomizedDialogs
      title="Submit your answer"
      content={
        <Box textAlign={"center"}>
          {mode === "login" ? (
            <Login handleMode={handleMode} />
          ) : (
            <Register handleMode={handleMode} />
          )}
          {/* <Typography variant="body2">or</Typography>
           <Link sx={{ cursor: "pointer" }} onClick={login.onSuccess}>
            <Typography variant="body2">Continue without an account</Typography>
          </Link> */}
        </Box>
      }
      maxWidth={"sm"}
      open={open}
      handleClose={handleClose}
    />
  );
}
