import React from "react";
import { Box } from "@mui/material";

import DataTable from "../Common/DataTable";
import Toolbar from "./Toolbar";
import { useForm } from "../services/challenges";

export default function Responses() {
  const { data: list } = useForm({ selection: "answered" });

  return (
    <Box>
      <Toolbar responseList={list} />
      <Box padding={1} />
      <DataTable rows={list} />
    </Box>
  );
}
