import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import PersonIcon from "@mui/icons-material/Person";

import { stringToHslColor } from "../Common/tools";
import FollowButton from "../Profile/FollowButton";
const getIcon = (index) => {
  if (index === 1) return <Typography variant="h4">&#x1F947;</Typography>;
  if (index === 2) return <Typography variant="h4">&#x1F948;</Typography>;
  if (index === 3) return <Typography variant="h4">&#x1F949;</Typography>;
  return (
    <Typography variant="h6" paddingLeft={2} paddingRight={2}>
      {index}
    </Typography>
  );
};
export default function CustomListItem({ item, refetchLeaderBoard }) {
  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      paddingTop={1}
      paddingBottom={1}
      paddingLeft={3}
      paddingRight={3}
    >
      <Box display={"flex"} gap={2} alignItems="center">
        <Box display={"flex"} gap={3} alignItems="center">
          {getIcon(item.weekRank)}
          <Avatar sx={{ background: stringToHslColor(item.handle) }}>
            <PersonIcon />
          </Avatar>
        </Box>
        <Typography variant="h6">{item.handle}</Typography>
        {item.myTeam ? (
          <Typography variant="h7"> (my team) </Typography>
        ) : null}
        {/*item.following === 0 ? (
          <FollowButton
            follow
            onSuccess={refetchLeaderBoard}
            handle={item.handle}
          />
        ) : null*/}
      </Box>
      <Typography variant="h6">{item.score} XP</Typography>
    </Box>
  );
}
