import React from "react";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import CheckIcon from "@mui/icons-material/Check";

import Copyright from "./Copyright";

export default function Thanks() {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box marginTop={8} />
      <Paper sx={{ padding: 4 }} elevation={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <CheckIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Thanks!
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body1">
                Your answer has been submitted. You may close this window or
                <Button component={Link} to="/feed">
                  view your feed
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
