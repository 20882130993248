import * as React from "react";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function DatePicker({
  value,
  handleChange,
  disabled,
  label = "",
}) {
  const [{ error, helperText }, setDateError] = React.useState({
    error: false,
    helperText: "",
  });
  const handleError = (reason, val) => {
    if (val === null) {
      setDateError({
        error: true,
        message: "Deadline is required",
      });
      return;
    }
    if (reason === "invalidDate") {
      setDateError({ error: true, message: "Invalid date" });
      return;
    }
    if (reason === "disablePast") {
      setDateError({
        error: true,
        message: "The deadline should be in the future",
      });
      return;
    }
    if (reason !== null) {
      setDateError({
        error: true,
        message: `Date error (${reason})`,
      });
      return;
    }
    setDateError({ error: false, message: "" });
    return;
  };
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/*         <StaticDatePicker
          disabled={disabled}
          displayStaticWrapperAs="desktop"
          inputFormat="DD/MM/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              sx={{ width: "100%" }}
            />
          )}
          disablePast
          label="Deadline"
          onError={handleError}
        /> */}
        <DateTimePicker
          ampm={false}
          disabled={disabled}
          displayStaticWrapperAs="desktop"
          inputFormat="YYYY/MM/DD hh:mm"
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              sx={{ width: "100%" }}
            />
          )}
          disablePast
          label={label}
          onError={handleError}
        />
        {/*   <TimeField
          value={value}
          onChange={handleChange}
          format="HH:mm"
          sx={{ width: 275 }}
        /> */}
      </LocalizationProvider>
      <Typography>{error.helperText}</Typography>
    </Box>
  );
}
