import React, { useContext, useState, useReducer } from "react";
import {
  Box,
  Stack,
  Typography,
  Chip,
  Grid,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment,
  Paper,
  Divider,
} from "@mui/material";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

import Sticky from 'react-stickynode';

import { Tweet } from 'react-tweet'

import SendIcon from "@mui/icons-material/Send";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpCenterTwoToneIcon from "@mui/icons-material/HelpCenterTwoTone";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";

import { usePublicChallenge, useAnswerChallenge } from "../services/challenges";
import { getPublicChallenge } from "../models/challenge";
import AuthContext from "../authContext";
import SubmitDialog from "./SubmitDialog";
import ShareLinkDialogButton from "../Common/ShareLinkDialogButton";
import DecideDialog from "./DecideDialog";
import CustomSlider from "./CustomSlider";
import MobileDesktopButton from "../Common/MobileDesktopButton";
import { challengeReducer } from "../Create/challengeReducer";
import DeleteDialog from "./DeleteDialog";
import AlertDialog from "./SkipAlert";
import CustomScatterChart from "./CustomScatterChart";
import CustomResponse from "./CustomResponse";
import AllData from "./AllData";
import { useFollow } from "../services/followers";
import FollowButton from "../Profile/FollowButton";
import FavoriteButton from "../Profile/FavoriteButton";
import ShowFavoritesOnlyButton from "../Profile/ShowFavoritesOnlyButton";
import ShowAllContentButton from "../Profile/ShowAllContentButton";
import ShowLinkButton from "../Profile/ShowLinkButton";

function isTweet(link) {
  const basicUrls = ['https://x.com/', 'https://www.x.com/', 'https://twitter.com/', 'https://www.twitter.com/'];
  var isTweet = false;
  for (var basicUrl of basicUrls) {
    isTweet = isTweet || String(link).substring(0, basicUrl.length) === basicUrl;
  }
  return isTweet
}

function getTweetId(link) {
  const parts = link.split('/');
  return parts[parts.length - 1]
}

export default function FormContent({
  linkCode: linkCodeProp,
  onSubmit,
  position,
}) {
  console.log('FormContent');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { login, loginDispatch } = useContext(AuthContext);
  const { linkCode: linkCodeParams } = useParams();
  const linkCode = linkCodeParams ?? linkCodeProp;
  const [formId, setFormId] = useState(0);
  const [challenge, challengeDispatch] = useReducer(
    challengeReducer,
    getPublicChallenge()
  );
  console.log(login);
  console.log("Done: " + challenge.done);
  console.log(JSON.stringify(challenge));
  const status = challenge.done ? "Review" : "New";
  const {
    data,
    refetch: refetchPublicChallenge,
    isLoading,
  } = usePublicChallenge({
    linkCode,
    minFollowingId: challenge.lastFollowingId,
    enabled: true,
    onSuccess: (data) => {
      if (data?.status === 1) {
        setFormId(data?.id ?? -1);
        const firstChallengeItem = data?.challenges?.[0];
        challengeDispatch({
          type: "INIT_PUBLIC_CHALLENGE",
          payload: firstChallengeItem,
        });
      }
    },
  });
  const isSkip = data?.challenges?.[0]?.myLastValue === challenge.myLastValue && data?.challenges?.[0]?.myLastComment === challenge.myLastComment;
  var whatNext = 1; //submit
  if (isSkip) {
    whatNext = 0
  }
  if (challenge.isExpired) {
    whatNext = 2
  }

  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showAllContent, setShowAllContent] = useState(false);
//  const [myLastValue, setMyLastValue] = useState(null);
//  const [myLastComment, setMyLastComment] = useState(null);

  const handlePrettoSlider = (newValue) => {
    challengeDispatch({
      type: "UPDATE_CHALLENGE",
      payload: { myLastValue: newValue },
    });
    if (!login.token)
      loginDispatch({ type: "SET_STATE", payload: { myLastValue: newValue } });
  };
  const mean =
    challenge.otherPredictions.filter(
      (pred) => pred.type === "stat" && pred.handle === "mean"
    )?.[0]?.lastValue ?? 50;

  const ownerHandle =
    challenge.otherPredictions.filter(({ id }) => id === challenge.ownerId)?.[0]
      ?.handle ?? "Unknown";

  // submit an answer
  const answerChallengeMutation = useAnswerChallenge({
    onSuccess: (data) => {
      console.log(data);
      if (!Object.values(data?.challengeResults).includes(2)) {
        if (onSubmit) {
          onSubmit();
        } else {
          navigate("/feed");
        }
      }
    },
  });
  const submitAnswer = () =>
    answerChallengeMutation.mutate({
      formId: data.id,
      challengeId: challenge.id,
      challengeAnswersJSON: JSON.stringify([
        { id: challenge.id, value: challenge.myLastValue, comment: challenge.myLastComment },
      ]),
    });

  const handleSubmit = () => {
    if (login.token) {
      // user is logged in
      submitAnswer();
    } else {
      // possibility to login / create account / submit answer anonymously
      setOpenSubmitDialog(true);
      loginDispatch({ type: "SET_ON_SUCCESS", payload: submitAnswer });
      loginDispatch({ type: "SET_VARIANT", payload: "submit" });
    }
  };
  const cancelSubmit = () => {
    setOpenSubmitDialog(false);
    loginDispatch({ type: "CANCEL_LOGIN" });
  };

  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  // follow user
  /*   const followMutation = useFollow({ onSuccess: refetchPublicChallenge });
  const handleFollow = ({ following, handle }) => {
    if (!following)
      followMutation.mutate({
        handle: handle,
        unfollow: 0,
      });
  };
 */
  if (isLoading) return <CircularProgress />;
  if (data?.fetchingFirstFromThisChannel && data?.status < 1) 
    navigate('/feed');
  if (data?.status < 1) 
    return (
      <Box textAlign={"center"}>
        <HelpCenterTwoToneIcon
          sx={{
            width: 128,
            height: 128,
            opacity: 0.4,
          }}
        />
        <Typography variant="h6">Form not found</Typography>
      </Box>
    );
  return (
    <Box>
      <Paper elevation={0} variant="outlined" style={{ borderBottomStyle: 'none', borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }}>
        <Box p={2}>
          <Box display="flex" gap={1}>
            <Chip
              label={position ? `${status} ${position}` : "Prediction"}
              color={position && !challenge.done ? "warning" : "primary"}
            />
            {challenge.deleted ? (
              <Chip
                label="Deleted"
                color="secondary"
              />
            ) : null}
            {/*
            <IconButton onClick={refetchPublicChallenge}>
              <RefreshIcon />
            </IconButton>
            */}
            {/*<ShareLinkDialogButton linkCode={linkCode} />*/}
            {data?.owner && challenge.canAdjudicate ? (
              <DecideDialog
                challenge={challenge}
                formId={formId}
                refetch={refetchPublicChallenge}
              />
            ) : null}
            {login.userId > 0 ? (
              <div>
                <MobileDesktopButton
                  component={Link}
                  to={`/b/${challenge.ownerId}`}
                  variant="outlined"
                  startIcon={<LeaderboardRoundedIcon />}
                >
                  Teams
                </MobileDesktopButton>
              </div>
            ) : null}
            {/** Show the delete button only for owner's forms. Don't show the delete button in the feed */}
            {data?.owner &&
              !challenge.deleted &&
              !pathname.includes("feed") ? (
              <DeleteDialog
                formId={formId}
                refetch={refetchPublicChallenge}
              />
            ) : null}
          </Box>
          <Box padding={1} />
          <Box display="flex" gap={1}>
            <Typography variant="h5">{challenge.question}</Typography>
            {/*challenge.isExpired ? (
              <Chip
                sx={{
                  background: "rgb(253, 237, 237)",
                  color: "rgb(95, 33, 32)",
                }}
                label={`Expired`} //${challenge.deadline.fromNow()}`}
                icon={<ScheduleOutlinedIcon />}
              />
              ) : null*/}
          </Box>
          {/** Formheader */}
          {challenge.eventStart.year() !== 1970 ? (
            <Typography variant="body2" color="textPrimary">
              Will happen between {challenge.eventStart.format("ddd DD/MM HH:mm")} and{" "}
              {challenge.eventStop.format("ddd DD/MM HH:mm")}
            </Typography>
          ) : null}
          <Typography variant="body2" color="textSecondary">
            {`${challenge.isExpired ? "Expired" : "Expires"
              } ${challenge.deadline.fromNow()}`}
            {/*` ${String.fromCharCode(8226)} Created by ${ownerHandle}`}
            {/*`&#x2022 ${challenge.Npredictors} ${challenge.Npredictors === 1 ? "response" : "responses"
              }`*/}
            {(challenge.teamId > 0) ? (
               ` ${String.fromCharCode(8226)} ${challenge.teamNpredictors} members of ${challenge.teamHandle} answered`):null}
          </Typography>
          {(challenge.detailsLink.length > 0) ? (
            <Typography variant="body2" color="textSecondary">
              <Link to={challenge.detailsLink} target="_blank"><div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{challenge.detailsLink}</div></Link>
            </Typography>
          ) : null}
        </Box>
      </Paper>

      <Sticky innerZ={999}>
        {(status) => {
          return (
            <Paper elevation={0} variant="outlined" style={{ borderTopStyle: 'none', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
              <Box p={2}>
                {(challenge.isExpired == false || challenge.done) ? (
                  <CustomSlider
                    handleValueChange={handlePrettoSlider}
                    disabled={challenge.isExpired}
                    refetchPublicChallenge={refetchPublicChallenge}
                    mean={mean}
                    challenge={challenge}
                    challengeDispatch={challengeDispatch}
                  />
                ) : null}
                {(status.status >= 0) ? (
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Stack spacing={1} direction="row" alignItems="center">
                      <ShowFavoritesOnlyButton showFavoritesOnly={showFavoritesOnly} setShowFavoritesOnly={setShowFavoritesOnly}/>
                      <ShowAllContentButton showAllContent={showAllContent} setShowAllContent={setShowAllContent} />
                      {(!showLink) ? (
                        <ShowLinkButton showLink={showLink} setShowLink={setShowLink} />
                      ) : null}
                    </Stack>
                    {(showLink) ? (
                      <TextField style={{ paddingRight: '16px' }}
                        fullWidth
                        placeholder="Add link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddCommentOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        defaultValue={challenge.myLastComment}
                        disabled={challenge.isExpired}
                        onChange={(event) =>
                          challengeDispatch({
                            type: "UPDATE_CHALLENGE",
                            payload: { myLastComment: event.target.value },
                          })
                        }
                        variant="standard"
                      />
                    ) : null}
                    <MobileDesktopButton
                      style={{ marginLeft: 'auto' }}
                      startIcon={<SendIcon />}
                      variant="contained"
                      onClick={()=>{
                        if (whatNext==0){
                            //if (pathname.includes("/f/")) handleOpenAlert();
                            if (onSubmit) onSubmit();
                            navigate("/feed");
                        }
                        if (whatNext==1){
                          handleSubmit();
                        }
                        if (whatNext==2){
                          //goto next new one from channel
                          console.log(challenge.ownerId);
                          navigate('/f/@id'+challenge.ownerId);
                        }
                      }}
                      disabled={
                        //challenge.isExpired ||
                        answerChallengeMutation.isLoading ||
                        isLoading
                      }
                      color="primary"
                    >
                      
                      {whatNext==0 ? "Skip" : null}
                      {whatNext==1 ? "Submit" : null}
                      {whatNext==2 ? "Next" : null}
                    </MobileDesktopButton>
                  </Stack>
                ) : null}
              </Box>
            </Paper>)
        }}
      </Sticky>

      <Box m={3} />
      <Paper elevation={0} variant="outlined">
        <Box p={2}>
          <Grid item md={10}>
            {/*
            <Box display="flex" alignItems={"center"}>
              <Typography>Responses</Typography>
              <Box style={{marginLeft:'auto'}}><ShowFavoritesOnlyButton challengeDispatch={challengeDispatch} challenge={challenge} /></Box>
            </Box>
            <AllData
              min={Number(challenge.minValue)}
              max={Number(challenge.maxValue)}
              data={challenge.otherPredictions
                .filter((pred) => pred.type !== "stat")
                .map((pred, index) => ({
                  x: pred.lastValue,
                  y: 0,
                  handle: pred.handle,
                  rank: pred.weekRank,
                  favorite: pred.favorite,
                }))}
            />
              */}
            <Box m={0} />
            {challenge.otherPredictions
              .filter((pred) => pred.type !== "stat" && (pred.favorite || showFavoritesOnly == false))
              .map((pred) => (
                <Box key={pred.id}>
                  <Box display="flex" gap={1} alignItems={"center"}>
                    <Typography>
                      {(pred.weekRank > 0) ? pred.weekRank : '*'}. {pred.handle}
                    </Typography>
                    <FavoriteButton
                      handle={pred.handle}
                      channelId={challenge.ownerId}
                      onSuccess={refetchPublicChallenge}
                      pred={pred}
                    />
                    <Typography style={{ marginLeft: 'auto' }} variant="body2" sx={{color:"grey"}}>
                      {pred.email}
                    </Typography>
                    {(pred.comment.length > 0) ? (
                      <Link style={{ marginLeft: 'auto' }} to={pred.comment} target="_blank">Link</Link>
                    ) : null}
                  </Box>
                  <CustomResponse
                    min={Number(challenge.minValue)}
                    max={Number(challenge.maxValue)}
                    data={[
                      {
                        x: pred.lastValue,
                        y: 0,
                        isMe: pred.id==login.userId,
                        handle: pred.handle,
                        email: pred.email,
                        rank: pred.localWeekRank,
                        favorite: pred.favorite,
                      },
                    ]}
                    variant={"user"}
                    valueFormat={challenge.settings.valueFormat}
                  />
                  {(showAllContent && pred.comment.length > 0) ? (
                    <Link to={pred.comment} target="_blank"><div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{pred.comment}</div></Link>
                  ) : null}
                  {(showAllContent && isTweet(pred.comment)) ? (
                    <Tweet id={getTweetId(pred.comment)} />
                  ) : null}
                </Box>
              ))}
          </Grid>
        </Box>
      </Paper>

      <SubmitDialog open={openSubmitDialog} handleClose={cancelSubmit} />
      <AlertDialog open={openAlert} handleClose={handleCloseAlert} />
    </Box>
  );
}
