import { Box, Typography } from "@mui/material";
import PollRoundedIcon from "@mui/icons-material/PollRounded";
import { useNavigate } from "react-router-dom";

export default function Logo({ onClick }) {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={onClick ? onClick : () => navigate("/responses")}
    >
      <PollRoundedIcon /> <Typography variant="h6">Cassandras</Typography>
    </Box>
  );
}
