import React, { useState } from "react";

import { Box, CircularProgress, Typography, Card } from "@mui/material";
import ProfileList from "../Profile/ProfileList";
import Carrousel from "./Carrousel";
import { useFeed } from "../services/challenges";
import EndOfFeed from "./EndOfFeed";
import { useEffect } from "react";

export default function Feed() {
  const [challenges, setChallenges] = useState({ type: "init", list: [] });
  const [showAlert, setShowAlert] = useState(false);
  const { data, isLoading, refetch } = useFeed();

  const newChallenges =
    data?.filter((item) => !item?.challenges?.[0]?.done) ?? [];
  const completedChallenges =
    data?.filter((item) => item?.challenges?.[0]?.done) ?? [];
  const allChallenges = [...completedChallenges, ...newChallenges];

  const handleEnd = () => {
    if (challenges.type === "new") setShowAlert(true); // show the review alert (no new challenges)
    if (challenges.type === "all") refetch(); // refetch the feed at the end of review part
  };

  const handleReview = () => {
    setShowAlert(false); // dismiss the alert
    setChallenges({ type: "all", list: allChallenges });
  };

  // init feed when new data is available
  useEffect(() => {
    if (newChallenges.length > 0) {
      setShowAlert(false);
      setChallenges({ type: "new", list: newChallenges });
    } else if (allChallenges.length > 0) {
      setShowAlert(true);
      setChallenges({ type: "all", list: allChallenges });
    }
  }, [data]);

  return (
    <div>
        {isLoading ? (
          <CircularProgress />
        ) : challenges.list.length === 0 ? (
          <Box >
            <Typography>
              There are no challenges for you. Recommended channels:
            </Typography>
            <Box padding={1} />
            <ProfileList scope={"priority"} />
          </Box>
        ) : (
          <React.Fragment>
            {showAlert ? (
              <EndOfFeed
                text={"Feel free to change your predictions now or later"}
                buttonText={"Review my predictions"}
                onSubmit={handleReview} // close the alert
              />
            ) : (
              <Carrousel challenges={challenges.list} handleEnd={handleEnd} />
            )}
          </React.Fragment>
        )}
    </div>
  );
}
