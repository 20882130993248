import React from "react";
import { Box, Typography, Popover, Button } from "@mui/material";
import { stringToHslColor } from "../Common/tools";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreateIcon from "@mui/icons-material/Create";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import PersonIcon from "@mui/icons-material/Person";
import { useFollow } from "../services/followers";
import MutationMessage from "../Common/MutationMessage";

const minWidth = 20;
const maxWidth = 40;

export default function UserLabel({
  name,
  height,
  isCreator,
  isMyLastValue,
  score,
  value,
  weekRank,
  showScore = true,
  following,
  refetch,
}) {
  const color = stringToHslColor(name);
  const onSuccess = () => {
    //alert(`You follow now ${name}`);
    refetch();
  };
  const followMutation = useFollow({ onSuccess: onSuccess });
  /*   const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
   const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  }; */
  //const id = open ? "share-popover" : undefined;

  const handleFollow = () => {
    if (!following)
      followMutation.mutate({
        handle: name,
        unfollow: 0,
      });
  };

  const getSxIcon = () => {
    return {
      color: "#fff",
      background: color,
      border: `2px solid ${color}`,
      borderRadius: "50%",
      padding: 0.4,
    };
  };

  const scoreWidth = score > 50 ? (score / 100) * maxWidth : minWidth;
  return (
    <Box>
      <MutationMessage
        mutation={followMutation}
        text={{ success: `You follow ${name}!` }}
        vertical="bottom"
      />
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
      >
        <FollowButton handle={name} onSuccess={handlePopoverClose} />
      </Popover> */}
      <Box display="flex" justifyContent={"center"}>
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            zIndex: 3,
            background: "rgba(255,255,255, 0.85)",
            borderRadius: 3,
            marginTop: "-18px"
          }}
        >
          {Math.round(value * 10) / 10}
        </Typography>
        <Box
          sx={{
            borderLeft: `5px solid ${color}`,
            height: height,
            borderRadius: 8,
            zIndex: 1,
          }}
        />
      </Box>
      <Box
        position={"absolute"}
        left={40}
        paddingLeft={showScore ? scoreWidth / 33 : 0}
        paddingTop={showScore ? scoreWidth / 33 : 0.5}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: color,
            background: "rgba(255,255,255, 0.85)",
            borderRadius: 3,
            fontWeight: 600
          }}
        >
          {isMyLastValue
            ? `me ${weekRank !== null ? `(#${weekRank})` : ""}`
            : weekRank !== null
            ? `${name} (#${weekRank})`
            : name}
        </Typography>
      </Box>
      <Box
        display="block"
        gap={1}
        textAlign="center"
        alignItems={"center"}
        marginTop={score ? "-1px" : "-4px"}
        onClick={handleFollow}
      >
        {showScore ? (
          <Box
            width={scoreWidth}
            height={scoreWidth}
            borderRadius={"50%"}
            sx={{
              border: `2px solid ${color}`,
              margin: "auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              bgColor: "#fff",
            }}
          >
            {score}
          </Box>
        ) : isMyLastValue ? (
          <SensorOccupiedIcon sx={getSxIcon()} />
        ) : isCreator ? (
          <CreateIcon sx={getSxIcon()} />
        ) : following ? (
          <PersonIcon sx={getSxIcon()} />
        ) : (
          <PersonAddIcon sx={getSxIcon()} />
        )}
      </Box>
    </Box>
  );
}
