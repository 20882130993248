class Follower {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id ?? Math.round(Math.random() * 10000000);
    this.handle = obj.handle ?? "";
  }
}

const getFollower = (obj) => {
  return new Follower(obj);
};

export const getFollowers = (followers) => {
  return followers?.map((follower) => getFollower(follower)) ?? [];
};

class Leaderboard {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id ?? Math.round(Math.random() * 10000000);
    this.index = obj.index ?? 0;
    this.handle = obj.handle ?? "";
    this.score = obj.weekScore != null ? Math.round(obj.weekScore) : 0;
    this.weekN = obj.weekN ?? 0;
    this.following = obj.following ?? 0;
    this.weekRank = obj.weekRank ?? 0;
    this.myTeam = obj.myTeam ?? false;
  }
}

export const getLeaderboard = (obj) => {
  return new Leaderboard(obj);
};

export const getLeaderboards = (leaderboards) => {
  return leaderboards.map((leaderboard, index) =>
    getLeaderboard({ ...leaderboard, index })
  );
};

class LeaderboardData {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.handle = obj.handle ?? "";
    this.myTeamHandle = obj.myTeamHandle ?? "";
    this.list = obj.list != null ? getLeaderboards(obj.list) : [];
  }
}

export const getLeaderboardData = (obj) => {
  return new LeaderboardData(obj);
};
