import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { routes } from "./routes";
import AuthProvider from "./AuthProvider";
import ProtectedRoute from "./ProtectedRoute";
import CookiesBanner from "./Toolbar/CookieBanner";

const theme = createTheme({
  palette: {},
  shape: {
    borderRadius: 8,
  },
});

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute publicPage={route.publicPage}>
                      {route.element}
                      <CookiesBanner />
                    </ProtectedRoute>
                  }
                />
              ))}
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
