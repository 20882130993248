import { useEffect, useState } from "react";
import {
  Paper,
  InputBase,
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useFindUser } from "../services/followers";
import Profile from "./Profile";

export default function ProfileList({ variant = "row", handleClose, scope }) {
  const [query, setQuery] = useState({ scope, query: "" });
  const handleSearch = (event) =>
    setQuery({ scope, query: event.target.value });

  const [profiles, setProfiles] = useState([]);
  const handleProfiles = (data) => setProfiles(data.slice(0, 4));
  const findUserMutation = useFindUser({ onSuccess: handleProfiles });
  const postSearch = () => findUserMutation.mutate(query);

  useEffect(() => {
    // get the users
    const timeOutId = setTimeout(postSearch, 500);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query]);

  return (
    <Box>
      <Paper
        sx={{
          p: "2px ",
          display: "flex",
          alignItems: "center",
          width: variant === "list" ? "100%" : 400,
        }}
        elevation={2}
      >
        <Box sx={{ p: "10px" }} aria-label="menu">
          <SearchIcon />
        </Box>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search on handle"
          inputProps={{ "aria-label": "search handle" }}
          onChange={handleSearch}
          autoFocus={true}
        />
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <Box padding={1} />
      {profiles.length === 0 ? (
        <Typography>No profiles were found</Typography>
      ) : (
        <Grid container spacing={2}>
          {profiles.map(({ id, handle, following }) => (
            <Grid item key={id} xs={variant === "list" ? 12 : null}>
              <Profile
                id={id}
                handle={handle}
                following={following}
                postSearch={postSearch}
                variant={variant}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
