import React from "react";
import { Link, useLocation } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Copyright from "./Copyright";

const register = {
  confirmation: (email) => (
    <React.Fragment>
      Your account verification email has been sent to {email}
    </React.Fragment>
  ),
  moreInfo: (
    <React.Fragment>
      Please <strong>check your inbox or spam folder</strong> and click on the
      link to complete your registration.
    </React.Fragment>
  ),
  action: <React.Fragment>Did you verify your email? Login</React.Fragment>,
};

const forgetPass = {
  confirmation: (email) => (
    <React.Fragment>
      Your email with a recovery link has been sent to {email}.
    </React.Fragment>
  ),
  moreInfo: (
    <React.Fragment>
      Please <strong>check your inbox or spam folder</strong> and click on the
      link to reset your password.
    </React.Fragment>
  ),
  action: <React.Fragment>Did you reset your password? Login</React.Fragment>,
};

export default function Verify() {
  const location = useLocation();
  const variant = location.state?.variant ?? "forgetPass";
  const email = location.state?.email ?? "your mailbox";
  const messages = variant === "forgetPass" ? forgetPass : register;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box marginTop={8} />
      <Paper sx={{ padding: 4 }} elevation={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <MailOutlineRoundedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Check your mailbox
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body1">
                {messages.confirmation(email)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{messages.moreInfo}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Link to="/login" variant="body2">
                {messages.action}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
