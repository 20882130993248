import { getChallenge, getPublicChallenge } from "../models/challenge";

export function challengeReducer(challenge, action) {
  switch (action.type) {
    case "INIT_CHALLENGE":
      return getChallenge(action.payload);
    case "INIT_PUBLIC_CHALLENGE":
      return getPublicChallenge(action.payload);
    case "UPDATE_CHALLENGE":
      return { ...challenge, ...action.payload };
    default:
      return challenge;
  }
}
