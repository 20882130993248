import { IconButton, Tooltip } from "@mui/material";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

export default function ShowFavoritesOnlyButton({ showFavoritesOnly, setShowFavoritesOnly }) {
  function renderFavoriteIcon(favorite) {
    if (favorite){
      return(<StarIcon />);
    }else{
      return(<StarBorderIcon />);
    }
  }
  
  var title="Show favorites only";  
  if (showFavoritesOnly){
    title="Show all";
  }

  return (
    <Tooltip title={title}>
      <IconButton
        onClick={() => {
            setShowFavoritesOnly(!showFavoritesOnly);
          }
        }
      >
      {renderFavoriteIcon(showFavoritesOnly)}
      </IconButton>
    </Tooltip>
  );
}
