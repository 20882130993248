import { Box, Container, Divider } from "@mui/material";
import Logo from "./Toolbar/Logo";
import useMediaQuery from "@mui/material/useMediaQuery";

import NavButtons from "./Toolbar";
import AccountMenu from "./Toolbar/AccountMenu";
import { useContext } from "react";
import AuthContext from "./authContext";

export default function Page({ Body }) {
  const {
    login: { userId, token },
  } = useContext(AuthContext);
  const isLoggedIn = userId > 0;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
        padding={1}
      >
        <Logo />

        {isLoggedIn ? <NavButtons /> : null}
        {isLoggedIn ? <AccountMenu /> : null}
      </Box>
      <Divider />
      <Box padding={2} />
      <Container>
        {token ? Body : null} {isMobile ? <Box p={6} /> : null}
      </Container>
    </Box>
  );
}
